import { all, takeLatest } from "redux-saga/effects";
import { checkIn, register, signOut, verify } from "../../slices/customerSlice";
import {
  handleSignIn,
  handleSignOut,
  handleSignUp,
  handleVerify,
} from "../handler/customerHandler";
export default function* customerSaga(): Generator<any, any, any> {
  yield all([
    yield takeLatest(checkIn.type, handleSignIn),
    yield takeLatest(register.type, handleSignUp),
    yield takeLatest(verify.type, handleVerify),
    yield takeLatest(signOut.type, handleSignOut),
    //yield takeLatest(updateUserInfo.type, handleUpdateContact),
  ]);
}
