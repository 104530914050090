import fetch from "../auth/FetchInterceptor";

const FinanceService = {
  reportChina: function (
    page: number,
    limit = 12,
    data: { status: string; startDate: Date; endDate: Date }
  ) {
    return fetch({
      url: `/report-china?page=${page}&limit=${limit}`,
      method: "post",
      headers: {
        "public-request": "true",
      },
      data: data,
    });
  },
  reportKazakhstan: function (
    page: number,
    limit = 12,
    data: { status: string; startDate: Date; endDate: Date }
  ) {
    return fetch({
      url: `/report-kazakhstan?page=${page}&limit=${limit}`,
      method: "post",
      headers: {
        "public-request": "true",
      },
      data: data,
    });
  },
  reportFinance: function (
    page: number,
    limit = 12,
    data: { startDate: Date; endDate: Date }
  ) {
    return fetch({
      url: `/report-finance?page=${page}&limit=${limit}`,
      method: "post",
      headers: {
        "public-request": "true",
      },
      data: data,
    });
  },
};

export default FinanceService;
