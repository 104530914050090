import axios from "axios";

import history from "../history";
import { AUTH_TOKEN } from "../redux/constants/Auth";
import { notification } from "antd";
import { API_BASE_URL } from "../configs/AppConfig";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

// Config
const ENTRY_ROUTE = "/login";
const TOKEN_PAYLOAD_KEY = "authorization";
const PUBLIC_REQUEST_KEY = "public-request";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const auth = JSON.parse(localStorage.getItem(AUTH_TOKEN) || "{}");
    const jwtToken = auth?.accessToken;

    if (!config.headers) {
      return;
    }
    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = "Bearer " + jwtToken;
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    let notificationParam = {
      message: "",
      description: "",
    };

    if (error.response === undefined) {
      notificationParam.message = "No connection";
      notificationParam.description = "Please check";
    }

    // Remove token and redirect
    if (error.response?.status === 400) {
      notificationParam.message = "Bad request";
      notificationParam.description = "Please try again";
    }
    if (error.response?.status === 403 || error.response?.status === 401) {
      notificationParam.message = "Authentication Fail";
      notificationParam.description = "Please login again";
      // console.log(history.location.pathname);

      sessionStorage.setItem("redirect", history.location.pathname);

      localStorage.removeItem(AUTH_TOKEN);
      history.push(ENTRY_ROUTE);
      // window.location.reload();
    }

    // if (error.response?.status === 422 || error.response?.status === 401) {
    //   notificationParam.message = "Authentication Fail";
    //   notificationParam.description = "Please login again";
    //   localStorage.removeItem(AUTH_TOKEN);
    //   history.push(ENTRY_ROUTE);
    //   window.location.reload();
    // }

    if (error.response?.status === 404) {
      notificationParam.message = "Not Found";
    }

    if (error.response?.status === 500) {
      notificationParam.message = "Internal Server Error";
    }

    if (error.response?.status === 508) {
      notificationParam.message = "Time Out";
    }

    if (!!error.response.data.message) {
      notificationParam.message = error.response.data.message;
      notificationParam.description = "";
    }
    notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service;
