import { current } from "@reduxjs/toolkit";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  List,
  Modal,
  notification,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import BarcodeReader from "react-barcode-reader";
import { OrderStatus } from "../home/statusOrder";
import SettingService from "../../../service/SettingService";
import OrderService from "../../../service/OrderService";
import { DeleteOutlined, SyncOutlined } from "@ant-design/icons";
import OrderDetails from "../order-details";
import { warn } from "console";
// import OrderService from "../../service/OrderService";
// import { DeleteOutlined, SyncOutlined } from "@ant-design/icons";
// import SettingService from "../../service/SettingService";
// import { OrderStatus } from "../../views/app-views/home/statusOrder";
// import OrderDetails from "../../views/app-views/order-details";
// import FilialDetails from "../../views/app-views/filial/FilialDetails";

export interface IORDERS {
  _id: string;
  status: OrderStatus;
  barCode: string;
  orderGroup: string;
}

const PickUpReadyBarcode = () => {
  const [scanedCodes, setScanCodes] = useState<IORDERS[]>([]);
  const [form] = Form.useForm();
  const [showIin, setShowIin] = useState(false);
  const [setting, setSetting] = useState({ price: 0, currency: 0 });
  const [refreshing, setFreshing] = useState(false);

  const [openEdit, setOpenEdit] = useState({
    open: false,
    orderGroupId: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);

  const handleCancelBarcode = (indexToDelete: number) => {
    setScanCodes((current) => {
      return current.filter(function (value, index, arr) {
        return index !== indexToDelete;
      });
    });
  };

  const handlePickUpReadyOrder = async () => {
    try {
      const res = await OrderService.readyPickupBarcode({
        barCodes: scanedCodes.map((item) => item.barCode),
      });

      notification.success({
        message: `OK`,
        description: "",
        placement: "topRight",
      });
      setScanCodes([]);
    } catch (error) {
      console.warn(error);
      notification.error({
        message: `Ошибка`,
        description: "",
        placement: "topRight",
      });
    }
  };

  // const sendReceiceOrderReq = async () => {
  //   const response = await OrderService.receive({
  //     barCodes: scanedCodes,
  //     // iin: "todo",
  //     iin: form.getFieldValue("iin"),
  //     fee: form.getFieldValue("fee"),
  //     weight: form.getFieldValue("weight"),
  //     currency: setting.currency,
  //     price: setting.price,
  //   });

  //   if (!!response.data.message) {
  //     notification.success({
  //       message: `OK`,
  //       description: response.data.message,
  //       placement: "topRight",
  //     });

  //     setScanCodes([]);
  //   } else {
  //     notification.warn({
  //       message: ``,
  //       description: "出错",
  //       placement: "topRight",
  //     });
  //   }
  // };

  const onCheckOrder = async (data: string) => {
    try {
      setLoadingOrder(true);
      const res = await OrderService.clientPreCheck({ barCode: data });
      if (scanedCodes.length <= 50) {
        setScanCodes((current) => [...current, res.data]);
      } else {
        alert("已超过50件货");
      }

      setLoadingOrder(false);
    } catch (error) {
      setLoadingOrder(false);
    }

    // setLoadingOrder(true);
    // const res = await OrderService.clientPreCheck({
    //   barCode: data,
    // });

    // console.log(res.data.barCode);

    // setScanCodes((current) => [...current, res.data.barCode]);

    // console.log(scanedCodes);

    // setLoadingOrder(false);
  };

  const handleCorrectOrder = async (barCode: string, index: number) => {
    try {
      setFreshing(true);
      const res = await OrderService.clientPreCheck({ barCode: barCode });

      setScanCodes((current) => {
        current[index] = res.data;
        return current;
      });
      // if (res.data.status !== OrderStatus.ARRIVED_AT_KAZAKHSTAN) {
      //   audioRef.current.play();
      //   setFreshing(false);
      //   return;
      // }

      setFreshing(false);
    } catch (error) {
      console.error(error);
      setFreshing(false);
    }
  };
  return (
    <div style={{ width: "100%" }}>
      {" "}
      {showIin === false ? (
        <BarcodeReader
          onError={(err: any) => {
            console.log(err);
          }}
          onScan={(data: string) => onCheckOrder(data)}
          // onScan={async (data: string) => {
          //   setLoadingOrder(true);
          //   const res = await OrderService.clientPreCheck({ barCode: data });
          //   setScanCodes((current) => [...current, res.data]);
          //   setLoadingOrder(false);
          //   // setLoadingOrder(true);
          //   // const res = await OrderService.clientPreCheck({
          //   //   barCode: data,
          //   // });

          //   // console.log(res.data.barCode);

          //   // setScanCodes((current) => [...current, res.data.barCode]);

          //   // console.log(scanedCodes);

          //   // setLoadingOrder(false);
          // }}
        />
      ) : null}
      <Row justify="center" style={{ width: "100%" }}>
        <Col span={16}>
          <Card>
            <Form
              style={{ marginBottom: 16 }}
              name="basic"
              onFinish={(val: any) => onCheckOrder(val.barCode)}
              // onFinish={(values: any) => onFinish(values)}
              layout="inline"
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label=""
                name="barCode"

                // rules={[{ required: true, message: "Пожалуйста, введите длину!!" }]}
              >
                <Input placeholder="条码/штрих-код" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  添加/Добавить
                </Button>
              </Form.Item>
            </Form>
            <List
              loading={loadingOrder}
              header={
                <div>
                  <b>条码</b> （填写后，点击《确认》接货）
                </div>
              }
              footer={
                <div>
                  <Button
                    type="primary"
                    loading={loading}
                    disabled={
                      scanedCodes.some(
                        (item: IORDERS) =>
                          item.status !== OrderStatus.ARRIVED_AT_KAZAKHSTAN
                      ) || scanedCodes.length > 50
                    }
                    //onClick={() => handlePickUpReadyOrder()}

                    onClick={() => handlePickUpReadyOrder()}
                    // onKeyDown={(event) => {
                    //   if (event.key === "Enter") {
                    //     console.log("enter press here! ");
                    //   }
                    // }}
                  >
                    OK
                  </Button>
                </div>
              }
              //filial todo
              bordered
              dataSource={scanedCodes}
              renderItem={(item, index) => (
                <List.Item
                  actions={[
                    item.status !== OrderStatus.ARRIVED_AT_KAZAKHSTAN ? (
                      <>
                        <Button
                          key="list-loadmore-edit"
                          type="link"
                          onClick={() => {
                            setOpenEdit({
                              open: true,
                              orderGroupId: item.orderGroup,
                            });
                            //handleCancelBarcode(index);
                          }}
                        >
                          单号状态不符合，请修改 / Неверный статус, исправьте
                        </Button>

                        <Button
                          onClick={() =>
                            handleCorrectOrder(item.barCode, index)
                          }
                          loading={refreshing}
                        >
                          {refreshing ? null : <SyncOutlined />}
                        </Button>
                      </>
                    ) : null,
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => handleCancelBarcode(index)}
                      danger
                    >
                      <DeleteOutlined />
                    </Button>,
                  ]}
                >
                  <div>
                    {" "}
                    <Typography.Text mark>{index + 1}.</Typography.Text>{" "}
                    {item.barCode}
                  </div>
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
      {openEdit.open ? (
        <OrderDetails openEdit={openEdit} setOpenEdit={setOpenEdit} />
      ) : null}
    </div>
  );
};

export default PickUpReadyBarcode;
