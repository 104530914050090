import { Empty, Input, Spin } from "antd";
import React, { useState } from "react";
import Cargo from "../../../img/cargo.jpg";
import OrderService from "../../../service/OrderService";
import { TimelineInterface } from "../order-details/OrderDetails";
import TimelineOrder from "../order-details/TimelineOrder";
import "../style.css";

const { Search } = Input;
function CheckOrder() {
  const [searchResult, setSerachResult] = useState<TimelineInterface[]>([]);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSearch = async (value: string) => {
    setLoading(true);
    const response = await OrderService.fetchTimelineByBarCode(value);

    setNotFound(response.data.length === 0);

    setSerachResult(response.data);
    setLoading(false);
  };

  return (
    <div
      className="checkOrder_wrapper"
      style={{ justifyContent: "flex-start", paddingTop: 25 }}
    >
      <img src={Cargo} alt="" width={300} style={{ marginBottom: 30 }} />
      <Search
        placeholder="Введите штрих-код вашего заказа"
        enterButton="Поиск"
        size="large"
        loading={false}
        onSearch={onSearch}
      />
      {loading ? (
        <Spin size="large" />
      ) : (
        <div className="searchResult__wrapper">
          {searchResult.length > 0 && (
            <TimelineOrder timeline={searchResult} message_ru={true} />
          )}
          {notFound && <Empty description={<span>Заказ не найден</span>} />}
        </div>
      )}
    </div>
  );
}

export default CheckOrder;
