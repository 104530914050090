import { Button, Card, Modal, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NoCodeOwnerService from "../../../../service/NoCodeOwnerService";
// import AddNoCodeOwner from "./AddNoCodeOwner";
// import EditNoCodeOwner from "./EditNoCodeOwner";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import AddNoCodeOwner from "./AddNoOwnerCode";
import EditNoCodeOwner from "./EditNoOwnerCode";

const { confirm } = Modal;

const NoCodeOwner = () => {
  const [openAdd, setOpenAdd] = useState(false);

  const [loading, setLoading] = useState(false);

  const [openEdit, setOpenEdit] = useState({
    open: false,
    NoCodeOwnerId: "",
  });
  const [NoCodeOwner, setNoCodeOwner] = useState<any>([]);

  const showDrawerAdd = () => {
    setOpenAdd(true);
  };

  const showDrawerEdit = (record: any) => {
    setOpenEdit({ open: true, NoCodeOwnerId: record._id });
  };

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      const result = await NoCodeOwnerService.fetchFilter(1);

      setNoCodeOwner({
        data: result.data.noOwnerBarCodes,
        total: result.data.total,
      });
      setLoading(false);
    };
    fetchOrders();
  }, []);

  const columns = [
    // {
    //   title: "品名",
    //   dataIndex: "key",
    //   key: "key",
    // },
    {
      title: "条码",
      dataIndex: "barCode",
      key: "barCode",
      width: "70%",
    },

    {
      title: "更多",
      key: "action",

      render: (text: string, record: any) => (
        <>
          <Space size="middle">
            <Button
              danger
              type="primary"
              onClick={() => {
                deleteNoCodeOwnerConfirm(record._id);
              }}
            >
              删除
            </Button>
          </Space>
          <Space size="middle">
            <Button
              type="primary"
              onClick={() => {
                showDrawerEdit(record);
              }}
            >
              修改
            </Button>
          </Space>
        </>
      ),
    },
  ];

  const pageChangeHandle = async (page: number, pageSize: number) => {
    setLoading(true);
    const res = await NoCodeOwnerService.fetchFilter(page);
    setNoCodeOwner({
      data: res.data.noOwnerBarCodes,
      total: res.data.total,
    });
    setLoading(false);
  };

  const deleteNoCodeOwner = async (id: string) => {
    await NoCodeOwnerService.delete(id);
    window.location.reload();
  };

  const deleteNoCodeOwnerConfirm = (id: string) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "确认删除？",
      okText: "确认",
      cancelText: "取消",
      onOk() {
        deleteNoCodeOwner(id);
      },
    });
  };

  return (
    <Card>
      <Button
        onClick={showDrawerAdd}
        style={{
          marginTop: 20,
          marginBottom: 20,
          marginLeft: 20,
          backgroundColor: "green",
        }}
        type="primary"
      >
        添加
      </Button>
      <Table
        loading={loading}
        dataSource={NoCodeOwner.data}
        columns={columns}
        rowKey="_id"
        pagination={{
          pageSize: 12,
          total: NoCodeOwner.total,
          onChange: pageChangeHandle,
        }}
      />
      {openAdd && (
        <AddNoCodeOwner openData={openAdd} setOpenData={setOpenAdd} />
      )}
      {openEdit.open && (
        <EditNoCodeOwner openData={openEdit} setOpenData={setOpenEdit} />
      )}
    </Card>
  );
};

export default NoCodeOwner;
