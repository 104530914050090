import { Button, Drawer, Form, InputNumber, Spin, notification } from "antd";
import { NotificationPlacement } from "antd/lib/notification";
import React, { useState } from "react";
import SettingService from "../../../../service/SettingService";

const AddSetting = ({ openData, setOpenData }: any) => {
  const [loading, setLoading] = useState(false);
  const openNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: `Success`,
      description: "添加成功",
      placement,
    });
  };
  const onFinish = async (values: any) => {
    setLoading(true);
    await SettingService.add({
      ...values,
    });
    openNotification("topRight");
    setLoading(false);
    window.location.reload();
  };

  const onClose = () => {
    setOpenData(false);
  };
  return (
    <Drawer
      title="添加分部"
      placement="right"
      onClose={onClose}
      open={openData}
      width={"50%"}
    >
      <Spin spinning={loading} size="large">
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="汇率/курс обмена" name="currency" required>
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="一公斤价格（阿拉木图）/ Цена за килограмм (Алматы)"
            name="price"
            required
          >
            <InputNumber />
          </Form.Item>
          <Form.Item

          // wrapperCol={{ offset: 8, span: 16 }}
          >
            <Button type="primary" htmlType="submit">
              添加
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default AddSetting;
