import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  notification,
  Pagination,
  Select,
  Space,
  Spin,
} from "antd";
import { NotificationPlacement } from "antd/lib/notification";
import React, { useEffect, useRef, useState } from "react";
import ProductOrderService from "../../../../service/ProductOrderService";
import TextArea from "antd/lib/input/TextArea";
import PhotoService from "../../../../service/PhotoService";
import {
  API_IMG_NO_OWNER_URL,
  API_IMG_URL,
} from "../../../../configs/AppConfig";

function EditProductOrder({ openData, setOpenData }: any) {
  const formRef = useRef<any>();
  const [loading, setLoading] = useState(true);
  const [photo, setPhoto] = useState<any>([]);
  const [selectLoading, setSelectLoading] = useState(false);
  useEffect(() => {
    const fetchProductOrder = async () => {
      const response = await ProductOrderService.getOne(openData.productId);

      formRef.current.setFieldsValue({
        _id: response.data._id,
        barCode: response.data.barCode,
        img: response.data.img,
      });
      setLoading(false);
    };
    fetchProductOrder();
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      const result = await PhotoService.fetchFilter(1);

      setPhoto({
        data: result.data.photos,
        total: result.data.total,
      });
      setLoading(false);
    };
    fetchOrders();
  }, []);

  const openNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: `Success`,
      description: "更改成功",
      placement,
    });
  };
  const onClose = () => {
    setOpenData({ open: false, ProductOrderId: "" });
  };
  const onFinish = async (values: any) => {
    setLoading(true);

    // _id: response.data._id,
    //     titleRU: response.data.titleRU,
    //     titleKZ: response.data.titleKZ,
    //     isMain: response.data.isMain,

    await ProductOrderService.update(values, openData.productId);
    openNotification("topRight");
    setLoading(false);
    window.location.reload();
  };

  const pageChangeHandle = async (page: number, pageSize: number) => {
    setSelectLoading(true);
    const res = await PhotoService.fetchFilter(page);
    setPhoto({
      data: res.data.photos,
      total: res.data.total,
    });
    setSelectLoading(false);
  };

  return (
    <Drawer
      title="修改FAQ"
      placement="right"
      onClose={onClose}
      open={openData.open}
      width={"50%"}
    >
      <Spin size="large" spinning={loading}>
        <Form
          ref={formRef}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            wrapperCol={{ offset: 8, span: 16 }}

            // wrapperCol={{ offset: 8, span: 16 }}
          >
            <Form.Item label="条码" name="barCode" required>
              <Input />
            </Form.Item>
            <Form.Item label="图片" name="img">
              <Select
                style={{ width: "100%" }}
                showSearch
                placeholder="请选图片"
                optionFilterProp="children"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <Space style={{ padding: "0 8px 4px" }}>
                      <Pagination
                        defaultCurrent={1}
                        total={photo?.total}
                        pageSize={12}
                        onChange={pageChangeHandle}
                      />
                    </Space>
                  </>
                )}
              >
                {photo?.data?.map((item: any) => (
                  <Select.Option key={item._id} value={item._id}>
                    <Image width={55} src={API_IMG_NO_OWNER_URL + item.img} />
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Button type="primary" htmlType="submit">
              添加
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default EditProductOrder;
