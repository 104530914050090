import fetch from "../auth/FetchInterceptor";
import { warehouseInterface } from "../views/app-views/order-details/OrderDetails";

const OrderGroupService = {
  add: function (data: any) {
    return fetch({
      url: `/orderGroup`,
      method: "post",
      data,
      headers: {
        "public-request": "true",
      },
    });
  },
  changeMultipleStatus: function (data: any) {
    return fetch({
      url: `/orderGroup-change-status-multiple`,
      method: "post",
      data,
      headers: {
        "public-request": "true",
      },
    });
  },
  update: function (id: string, data: any) {
    return fetch({
      url: `/update-orderGroup/${id}`,
      method: "put",
      data,
      headers: {
        "public-request": "true",
      },
    });
  },
  fetchOrderGroup: function (
    page: number,
    limit = 12,
    isKz = false,
    search = ""
  ) {
    return fetch({
      url: `/orderGroup-filter?page=${page}&limit=${limit}&isMobile=false&isKz=${isKz}&search=${search}`,
      method: "get",
      headers: {
        "public-request": "true",
      },
    });
  },

  delete: function (orderGroup: string) {
    return fetch({
      url: `/orderGroup/${orderGroup}`,
      method: "delete",
      headers: {
        "public-request": "true",
      },
    });
  },

  scanQr: function (data: { qrCodeId: string }) {
    return fetch({
      url: "/scanned-qr-code",
      method: "post",
      headers: {
        "public-request": "true",
      },
      data: data,
    });
  },

  fetchOneOrderGroup: function (orderGroup: string) {
    return fetch({
      url: `/orderGroup/${orderGroup}`,
      method: "get",
      headers: {
        "public-request": "true",
      },
    });
  },

  sendChina: function (
    id: string,
    data: {
      woodFee: number;
      packageFee: number;
      weight: number;
      warehouse: string;
    }
  ) {
    return fetch({
      url: "/orderGroup/send-china/" + id,
      method: "post",
      headers: {
        "public-request": "true",
      },
      data: data,
    });
  },
  recieveKz: function (id: string) {
    return fetch({
      url: "/orderGroup/recieve-kz/" + id,
      method: "post",
      headers: {
        "public-request": "true",
      },
      // data: data,
    });
  },
};

export default OrderGroupService;
