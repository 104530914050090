import CustomerService, {
  ICustomerLogin,
  ICustomerRegister,
  ICustomerVerify,
} from "../../../service/CustomerService";
// import UserService from "../../../service/UserService";

// export function signOutRequest() {
//   return CustomerService.signOut();
// }

export function verifyRequest(data: ICustomerVerify) {
  return CustomerService.verify(data);
}

export function signUpRequest(data: ICustomerRegister) {
  return CustomerService.register(data);
}

export function signInRequest(data: ICustomerLogin) {
  return CustomerService.login(data);
}

// export function updateContactRequest(data: any) {
//   return UserService.updateContactInfo(data);
// }
