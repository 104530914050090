import fetch from "../auth/FetchInterceptor";
const PhotoService = {
  fetchFilter: function (page: number, limit = 12) {
    return fetch({
      url: `/photo/list?page=${page}&limit=${limit}`,
      method: "get",
      headers: {
        "public-request": "true",
      },
    });
  },

  create: function (data?: any) {
    return fetch({
      url: `/photo/create`,
      method: "post",
      data: data,
      headers: {
        "Content-Type": `multipart/form-data`, // Set the content type to handle form data
      },
    });
  },

  delete: function (data: string) {
    return fetch({
      url: `/photo/delete/${data}`,
      method: "delete",
      headers: {
        "public-request": "true",
      },
    });
  },
};

export default PhotoService;
