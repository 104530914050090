import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import rootSaga from "./sagas/indexSaga";
import createSagaMiddleware from "redux-saga";
import customerSlice from "./slices/customerSlice";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    auth: userSlice,
    customer: customerSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;
