import { useEffect } from "react";
import fetch from "../auth/FetchInterceptor";
import { PLAN_TYPE } from "./OrderService";

// export interface qrCodeDataInterface {
//   orderId: string;
//   qrType: string;
//   qrId: string;
//   warehouse: string;
// }

const CategoryService = {
  fetchFilter: function (page: number, limit = 12, search: string) {
    return fetch({
      url: `/category-filter?page=${page}&limit=${limit}&search=${search}`,
      method: "get",
      headers: {
        "public-request": "true",
      },
    });
  },
  update: function (data: any, id: string) {
    return fetch({
      url: `/category/${id}`,
      method: "put",
      data: data,
      headers: {
        "public-request": "true",
      },
    });
  },
  getOne: function (data: string) {
    return fetch({
      url: `/category/${data}`,
      method: "get",
      headers: {
        "public-request": "true",
      },
    });
  },

  add: function (data: any) {
    return fetch({
      url: `/category/create`,
      method: "post",
      data: data,
      headers: {
        "public-request": "true",
      },
    });
  },

  delete: function (data: string) {
    return fetch({
      url: `/category/${data}`,
      method: "delete",
      headers: {
        "public-request": "true",
      },
    });
  },
};

export default CategoryService;
