import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Space,
  Tooltip,
} from "antd";
import React from "react";
import Cargo from "../../../img/cargo.jpg";
import { PlanTypeTextData } from "../../../service/OrderService";
import PricelistService, {
  calculatorInterface,
  CargoType,
  CargoTypeTextData,
} from "../../../service/PricelistService";
import "../style.css";

function Calculator() {
  // const [result, setResult] = useState();

  const info = (result: string) => {
    Modal.info({
      title: "Результат",
      content: (
        <div>
          <p>{result}</p>
        </div>
      ),
      onOk() {},
    });
  };
  const onFinish = (values: calculatorInterface) => {
    PricelistService.calculator(values).then((res) => {
      if (res.status === 200) {
        info(`Стоимость: ${res.data}$/кг`);
      } else if (res.status === 500) {
        info(res.data);
      }
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("Failed:", errorInfo);
  };
  return (
    <div className="checkOrder_wrapper" style={{ padding: 10 }}>
      <img src={Cargo} alt="" width={200} style={{ marginBottom: 10 }} />
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Длина(м)"
          name="length"
          rules={[{ required: true, message: "Пожалуйста, введите длину!!" }]}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          label="Ширина(м)"
          name="width"
          rules={[{ required: true, message: "Пожалуйста, введите ширину" }]}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          label="Высота(м)"
          name="height"
          rules={[{ required: true, message: "Пожалуйста, введите Высоту" }]}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          label="Вес(кг)"
          name="weight"
          rules={[{ required: true, message: "Пожалуйста, введите Вес" }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Название"
          name="title"
          rules={[{ required: true, message: "Пожалуйста, введите название" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Тип"
          name="cargo_type"
          rules={[{ required: true, message: "Пожалуйста, выберите тип" }]}
        >
          <Radio.Group>
            <Space direction="vertical">
              {CargoTypeTextData.map((data) =>
                data.key !== CargoType.BIG_CARGO &&
                data.key !== CargoType.SMALL_CARGO ? (
                  <Radio key={data.key} value={data.key}>
                    {data.text_ru}
                  </Radio>
                ) : (
                  <Tooltip
                    title={
                      data.key === CargoType.BIG_CARGO
                        ? "сумки, обувь, накладные волосы"
                        : "Все предметы первой необходимости"
                    }
                    placement="left"
                    color={"#f50"}
                    key={data.key}
                  >
                    <Radio key={data.key} value={data.key}>
                      {data.text_ru}
                    </Radio>
                  </Tooltip>
                )
              )}
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="План"
          name="plan"
          rules={[{ required: true, message: "Пожалуйста, выберите план" }]}
        >
          <Radio.Group>
            {PlanTypeTextData.map((elem) => (
              <Radio key={elem.key} value={elem.key}>
                {elem.text_ru}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Calculator;
