import { Button, Drawer, Form, Input, notification, Select, Spin } from "antd";
import { NotificationPlacement } from "antd/lib/notification";
import React, { useEffect, useRef, useState } from "react";
import PricelistService, { CargoType } from "../../../service/PricelistService";

function EditPriceList({ openData, setOpenData }: any) {
  const formRef = useRef<any>();
  const [loading, setLoading] = useState(true);
  const keys1 = Object.keys(CargoType);

  useEffect(() => {
    const fetchPriceList = async () => {
      const response = await PricelistService.getOne(openData.priceListId);

      formRef.current.setFieldsValue({
        _id: response.data._id,
        density_from: response.data.density_from,
        density_to: response.data.density_to,
        plane_plan: response.data.plane_plan,
        train_plan: response.data.train_plan,
        standard_plan: response.data.standard_plan,
        express_plan: response.data.express_plan,
        cargo_type: response.data.cargo_type,
      });
      setLoading(false);
    };
    fetchPriceList();
  }, []);

  const openNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: `Success`,
      description: "更改成功",
      placement,
    });
  };
  const onClose = () => {
    setOpenData({ open: false, priceListId: "" });
  };
  const onFinish = async (values: any) => {
    setLoading(true);

    await PricelistService.update(
      {
        density_from: parseInt(values.density_from),
        density_to: parseInt(values.density_to),
        express_plan: parseFloat(values.express_plan),
        plane_plan: parseFloat(values.plane_plan),
        standard_plan: parseFloat(values.standard_plan),
        train_plan: parseFloat(values.train_plan),
        cargo_type: values.cargo_type,
      },
      openData.priceListId
    );
    openNotification("topRight");
    setLoading(false);
  };

  return (
    <Drawer
      title="修改价格表"
      placement="right"
      onClose={onClose}
      open={openData.open}
    >
      <Spin size="large" spinning={loading}>
        <Form
          ref={formRef}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item name="_id" style={{ display: "none" }}>
            <Input type={"hidden"} />
          </Form.Item>
          <Form.Item
            label="密度1"
            name="density_from"
            rules={[{ required: true, message: "请输入" }]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            label="密度2"
            name="density_to"
            rules={[{ required: true, message: "请输入" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="飞机运$"
            name="plane_plan"
            rules={[{ required: true, message: "请输入飞机运" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="火车运$"
            name="train_plan"
            rules={[{ required: true, message: "请输入火车运" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="标准运$"
            name="standard_plan"
            rules={[{ required: true, message: "请输入标准运" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="快运$"
            name="express_plan"
            rules={[{ required: true, message: "请输入快运" }]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            label="货类"
            name="cargo_type"
            rules={[{ required: true, message: "请选择货类" }]}
          >
            <Select style={{ width: 120 }}>
              {keys1.map((key) => (
                <Select.Option key={key} value={key} selected={key}>
                  {key}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default EditPriceList;
