import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  InputNumber,
  notification,
  Pagination,
  Select,
  Space,
  Spin,
} from "antd";
import { NotificationPlacement } from "antd/lib/notification";
import React, { useEffect, useRef, useState } from "react";
import ProductService from "../../../../service/ProductService";
import TextArea from "antd/lib/input/TextArea";
import PhotoService from "../../../../service/PhotoService";
import {
  API_IMG_NO_OWNER_URL,
  API_IMG_PRODUCT,
  API_IMG_URL,
} from "../../../../configs/AppConfig";
import PhotoProductService from "../../../../service/PhotoProductService";
import CategoryService from "../../../../service/CategoryService";

function EditProduct({ openData, setOpenData }: any) {
  const formRef = useRef<any>();
  const [loading, setLoading] = useState(true);
  const [photo, setPhoto] = useState<any>([]);
  const [selectLoading, setSelectLoading] = useState(false);

  const [categories, setCategories] = useState<any>([]);
  const [selectCategoryLoading, setSelectCategoryLoading] = useState(false);

  useEffect(() => {
    const fetchProduct = async () => {
      const response = await ProductService.getOne(openData.ProductId);

      formRef.current.setFieldsValue({
        _id: response.data._id,
        title_ru: response.data.title_ru,
        title_kk: response.data.title_kk,
        title_zh: response.data.title_zh,
        desc_ru: response.data.desc_ru,
        desc_zh: response.data.desc_zh,
        desc_kk: response.data.desc_kk,
        quantity: response.data.quantity,
        price: response.data.price,
        img: response.data.img._id,
        category: response.data.category,
      });
      setLoading(false);
    };
    fetchProduct();
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      const result = await PhotoProductService.fetchFilter(1);

      setPhoto({
        data: result.data.productPhotos,
        total: result.data.total,
      });
      setLoading(false);
    };
    const fetchCategory = async () => {
      setSelectCategoryLoading(true);
      const result = await CategoryService.fetchFilter(1, 20, "");

      setCategories({
        data: result.data.categories,
        total: result.data.total,
      });
      setSelectCategoryLoading(false);
    };
    fetchOrders();
    fetchCategory();
  }, []);

  const openNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: `Success`,
      description: "更改成功",
      placement,
    });
  };
  const onClose = () => {
    setOpenData({ open: false, ProductId: "" });
  };
  const onFinish = async (values: any) => {
    setLoading(true);

    // _id: response.data._id,
    //     title_ru: response.data.title_ru,
    //     title_kk: response.data.title_kk,
    //     isMain: response.data.isMain,

    await ProductService.update(values, openData.ProductId);
    openNotification("topRight");
    setLoading(false);
    window.location.reload();
  };

  const pageChangeHandle = async (page: number, pageSize: number) => {
    setSelectLoading(true);
    const res = await PhotoProductService.fetchFilter(page);
    setPhoto({
      data: res.data.productPhotos,
      total: res.data.total,
    });
    setSelectLoading(false);
  };
  const pageChangeCategoryHandle = async (page: number, pageSize: number) => {
    setSelectCategoryLoading(true);
    const result = await CategoryService.fetchFilter(page, 20, "");

    setCategories({
      data: result.data.categories,
      total: result.data.total,
    });
    setSelectCategoryLoading(false);
  };

  return (
    <Drawer
      title="修改"
      placement="right"
      onClose={onClose}
      open={openData.open}
      width={"50%"}
    >
      <Spin size="large" spinning={loading}>
        <Form
          ref={formRef}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="名称RU" name="title_ru" required>
            <Input />
          </Form.Item>
          <Form.Item label="名称KZ" name="title_kk" required>
            <Input />
          </Form.Item>
          <Form.Item label="名称CN" name="title_zh" required>
            <Input />
          </Form.Item>
          <Form.Item label="介绍RU" name="desc_ru" required>
            <TextArea />
          </Form.Item>
          <Form.Item label="介绍KZ" name="desc_kk" required>
            <TextArea />
          </Form.Item>
          <Form.Item label="介绍CN" name="desc_zh" required>
            <TextArea />
          </Form.Item>
          <Form.Item label="价格" name="price" required>
            <InputNumber />
          </Form.Item>
          <Form.Item label="数量" name="quantity" required>
            <InputNumber />
          </Form.Item>
          <Form.Item label="图片" name="img">
            <Select
              loading={selectLoading}
              style={{ width: "100%" }}
              showSearch
              placeholder="请选图片"
              optionFilterProp="children"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Pagination
                      defaultCurrent={1}
                      total={photo?.total}
                      pageSize={12}
                      onChange={pageChangeHandle}
                    />
                  </Space>
                </>
              )}
            >
              {photo?.data?.map((item: any) => (
                <Select.Option key={item._id} value={item._id}>
                  <Image width={55} src={API_IMG_PRODUCT + item.img} />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="类别" name="category">
            <Select
              loading={selectCategoryLoading}
              style={{ width: "100%" }}
              showSearch
              placeholder="类别"
              optionFilterProp="children"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Pagination
                      defaultCurrent={1}
                      total={photo?.total}
                      pageSize={12}
                      onChange={pageChangeCategoryHandle}
                    />
                  </Space>
                </>
              )}
            >
              {categories?.data?.map((item: any) => (
                <Select.Option key={item._id} value={item._id}>
                  <p>{item.title_zh}</p>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            wrapperCol={{ offset: 8, span: 16 }}

            // wrapperCol={{ offset: 8, span: 16 }}
          >
            <Button type="primary" htmlType="submit">
              修改
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default EditProduct;
