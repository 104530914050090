import { createSlice } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "../constants/Auth";

const getAuthState = () => {
  const auth = localStorage.getItem(AUTH_TOKEN) || "{}";
  try {
    const authobj = JSON.parse(auth);
    return authobj;
  } catch (error) {
    console.error(error);
  }
};

const authLocalState = getAuthState(); // token and expireDate

export const userSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    message: "",
    showMessage: false,
    redirect: "",
    accessToken: authLocalState?.accessToken,
    refreshToken: authLocalState?.refreshToken,
    userInfo: {
      id: "",
      username: "",
      name: "",
    },
  },
  reducers: {
    signIn: (state, payload) => state,

    signUp: (state) => state,

    signOut: (state) => state,

    updateUserInfo: (state, action) => state,

    authenticated: (state, action) => {
      return {
        ...state,
        loading: false,
        redirect: "/",
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        userInfo: {
          id: action.payload.id,
          username: action.payload.username,
          name: action.payload.name,
        },
      };
    },

    showAuthMessage: (state, action) => {
      return {
        ...state,
        message: action.payload,
        showMessage: true,
        loading: false,
      };
    },

    hideAuthMessage: (state) => {
      return { ...state, message: "", showMessage: false };
    },

    signOutSuccess: (state) => {
      return { ...state, jwt: null, redirect: "/", loading: false };
    },

    signUpSuccess: (state) => {
      return { ...state, loading: false };
    },

    showLoading: (state) => {
      return { ...state, loading: true };
    },

    fillContactInfo: (state, action) => {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          id: action.payload.id,
          email: action.payload.email,
          telephone: action.payload.telephone,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
        },
      };
    },
  },
});

export const selectLoading = (state: any) => state.auth.loading;
export const selectMessage = (state: any) => state.auth.message;
export const selectShowMessage = (state: any) => state.auth.showMessage;
export const selectRedirect = (state: any) => state.auth.redirect;
export const selectToken = (state: any) => state.auth.accessToken;
export const selectUserInfo = (state: any) => state.auth.userInfo;
export const selectExpirationDate = (state: any) => state.auth.expirationDate;

export const {
  signIn,
  signUp,
  signOut,
  updateUserInfo,
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  signUpSuccess,
  showLoading,
  fillContactInfo,
} = userSlice.actions;
export default userSlice.reducer;
