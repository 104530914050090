import React, { useState } from "react";
import { TimelineInterface } from "../order-details/OrderDetails";
import OrderService from "../../../service/OrderService";
import { Card, Divider, Empty, Input, Spin, Tag } from "antd";
import { OrderStatus } from "../home/statusOrder";
import { getOrderStatusEnumText } from "../../../utils/getEnumText";

const { Search } = Input;
const SearchBarcode = () => {
  const [searchResult, setSerachResult] = useState({
    order: {
      barCode: null,
      iin: null,
      status: OrderStatus.PENDING,
    },
    orderGroup: {
      title: "",
    },
    customerBarCode: {
      owner: {
        name: "",
        telephone: "",
      },
    },
    isNoOwner: false,
  });
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSearch = async (value: string) => {
    setLoading(true);
    const response = await OrderService.globalSearch(value);
    if (response.status !== 200) {
      //   setSerachResult([]);
      setLoading(false);
      setNotFound(true);
      return;
    }

    setNotFound(response.data.length === 0);

    setSerachResult(response.data);
    setLoading(false);
  };
  return (
    <div
      className="checkOrder_wrapper"
      style={{
        justifyContent: "flex-start",
        paddingTop: 25,
        paddingBottom: 25,
      }}
    >
      <Search
        placeholder="条码"
        enterButton="搜索"
        size="large"
        loading={false}
        onSearch={onSearch}
      />
      {loading ? (
        <Spin size="large" />
      ) : (
        <div className="searchResult__wrapper">
          {/* {searchResult.length > 0 && <p></p>} */}

          {notFound ? (
            <Empty description={<span>Заказ не найден</span>} />
          ) : (
            <Card>
              <Card title={searchResult?.order?.barCode}>
                <p>
                  <span>条码组:</span> <b>{searchResult?.orderGroup?.title}</b>
                </p>
                <p>
                  <span>收货人:</span> <b>{searchResult?.order?.iin}</b>
                </p>
                <p>
                  <span>条码个人账号(姓名):</span>{" "}
                  <b>{searchResult?.customerBarCode?.owner?.name}</b>
                </p>
                <p>
                  <span>条码个人账号(电话):</span>{" "}
                  <b>{searchResult?.customerBarCode?.owner?.telephone}</b>
                </p>
                <p>
                  <span>条码状态:</span>{" "}
                  <b>
                    {getOrderStatusEnumText(searchResult?.order?.status)?.text}
                  </b>
                </p>
                <Divider />
                {searchResult.isNoOwner ? <Tag color="blue">无主</Tag> : null}
              </Card>
            </Card>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchBarcode;
