import {
  Button,
  Checkbox,
  Drawer,
  Form,
  Input,
  message,
  notification,
  Select,
  Spin,
  Upload,
  UploadFile,
} from "antd";
import { NotificationPlacement } from "antd/lib/notification";
import React, { useState } from "react";
import PhotoBarcodeService from "../../../../service/PhotoBarcodeService";
import TextArea from "antd/lib/input/TextArea";
import { UploadOutlined } from "@ant-design/icons";
import { API_BASE_URL } from "../../../../configs/AppConfig";
import axios from "axios";
import { RcFile, UploadProps } from "antd/lib/upload";

function AddPhotoBarcode({ openData, setOpenData }: any) {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);

  const openNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: `Success`,
      description: "添加成功",
      placement,
    });
  };

  // const beforeUpload = (file: any) => {
  //   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  //   if (!isJpgOrPng) {
  //     message.error("You can only upload JPG/PNG file!");
  //   }
  //   const isLt2M = file.size / 1024 / 1024 < 3;
  //   if (!isLt2M) {
  //     message.error("必须小于 3MB!");
  //   }
  //   //return isJpgOrPng && isLt2M;
  //   return false;
  // };

  const onClose = () => {
    setOpenData(false);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("image", file as RcFile);
    });

    try {
      setUploading(true);
      // You can use any AJAX library you like
      await PhotoBarcodeService.create(formData);
      openNotification("topRight");
      setUploading(false);
      setFileList([]);
      onClose();
      window.location.reload();
    } catch (error) {
      setUploading(false);
      alert("出错");
    }

    // fetch("https://www.mocky.io/v2/5cc8019d300000980a055e76", {
    //   method: "POST",
    //   body: formData,
    // })
    //   .then((res) => res.json())
    //   .then(() => {
    //     setFileList([]);
    //     message.success("upload successfully.");
    //   })
    //   .catch(() => {
    //     message.error("upload failed.");
    //   })
    //   .finally(() => {
    //     setUploading(false);
    //   });
  };

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };
  return (
    <Drawer
      title={`添加`}
      placement="right"
      onClose={onClose}
      open={openData.open}
      width={"50%"}
    >
      {/* <Spin spinning={loading} size="large"> */}
      <>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>请选择</Button>
        </Upload>
        <Button
          type="primary"
          onClick={handleUpload}
          disabled={fileList.length === 0}
          loading={uploading}
          style={{ marginTop: 16 }}
        >
          {uploading ? "上传中" : "已上传"}
        </Button>
      </>
      {/* </Spin> */}
    </Drawer>
  );
}

export default AddPhotoBarcode;
