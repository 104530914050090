import { call, put } from "redux-saga/effects";
import {
  signInRequest,
  signOutRequest,
  signUpRequest,
  // updateContactRequest,
} from "../request/userRequest";
import {
  authenticated,
  showAuthMessage,
  signOutSuccess,
} from "../../slices/userSlice";
import { AUTH_TOKEN } from "../../constants/Auth";

export function* handleSignOut(): Generator<any, any, any> {
  try {
    const response = yield call(signOutRequest);
    const { data } = response;
    if (data) {
      localStorage.removeItem("auth");
      yield put(signOutSuccess());
    } else {
      yield put(showAuthMessage(data?.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* handleSignUp(action: any): Generator<any, any, any> {
  try {
    const response = yield call(signUpRequest, action.payload);

    if (response?.message === "User registered successfully!") {
      const responseSignIn = yield call(signInRequest, action.payload);
      const { accessToken } = responseSignIn;
      const auth_token = {
        accessToken,
      };
      localStorage.setItem(AUTH_TOKEN, JSON.stringify(auth_token));
      yield put(authenticated(responseSignIn));
    }
  } catch (error: any) {
    console.warn(error.response.data.message);
    yield put(showAuthMessage(error.response.data.message));
  }
}

export function* handleSignIn(action: any): Generator<any, any, any> {
  // const { email, password } = action.payload;
  try {
    const response = yield call(signInRequest, action.payload);
    const { accessToken } = response;

    const auth_token = {
      accessToken,
    };

    if (response.message) {
      yield put(showAuthMessage(response.message));
    } else {
      localStorage.setItem(AUTH_TOKEN, JSON.stringify(auth_token));
      yield put(authenticated(response));
    }
  } catch (err: any) {
    console.warn(err);
    yield put(showAuthMessage(err.response.data.message));
  }
}

// export function* handleUpdateContact(action: any): Generator<any, any, any> {
//   try {
//     //const response = yield call(updateContactRequest, action.payload);
//     yield put(fillContactInfo(response));
//   } catch (error) {
//     console.warn(error);
//   }
// }
