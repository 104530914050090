import { current } from "@reduxjs/toolkit";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  List,
  Modal,
  notification,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import BarcodeReader from "react-barcode-reader";
import OrderService from "../../service/OrderService";
import { DeleteOutlined, SyncOutlined } from "@ant-design/icons";
import SettingService from "../../service/SettingService";
import { OrderStatus } from "../../views/app-views/home/statusOrder";
import OrderDetails from "../../views/app-views/order-details";
import FilialDetails from "../../views/app-views/filial/FilialDetails";

export interface IORDERS {
  _id: string;
  status: OrderStatus;
  barCode: string;
  orderGroup: string;
}

const RecieveBarcode = ({ isFilial }: { isFilial: boolean }) => {
  const [scanedCodes, setScanCodes] = useState<IORDERS[]>([]);
  const [form] = Form.useForm();
  const [showIin, setShowIin] = useState(false);
  const [setting, setSetting] = useState({ price: 0, currency: 0 });
  const [refreshing, setFreshing] = useState(false);

  const [openEdit, setOpenEdit] = useState({
    open: false,
    orderGroupId: "",
  });

  const [feeDisabled, setFeeDisabled] = useState(false);
  const [reportInfo, setReportInfo] = useState<{
    fee?: string | number | undefined;
    weight?: string | number | undefined;
  }>();

  const [loading, setLoading] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);

  const handleCancelBarcode = (indexToDelete: number) => {
    setScanCodes((current) => {
      return current.filter(function (value, index, arr) {
        return index !== indexToDelete;
      });
    });
  };

  useEffect(() => {
    async function fetchSetting() {
      const res = await SettingService.init();
      setSetting(res.data);
    }
    fetchSetting();
  }, []);

  const handleFinishConfirmRecivedOrder = async () => {
    console.log("jas");
    try {
      setLoading(true);

      if (scanedCodes.length === 0) {
        notification.error({
          message: `数据不能为空/Данные не должны быть пустыми.`,
          placement: "topRight",
        });
        return;
      }

      if (isFilial === true) {
        form.setFieldValue("fee", 0);
        form.setFieldValue("weight", 0);
      }

      const response = await OrderService.receive({
        barCodes: scanedCodes.map((item) => item.barCode),
        iin: form.getFieldValue("iin"),
        fee: form.getFieldValue("fee"),
        weight: form.getFieldValue("weight"),
        currency: setting.currency,
        price: setting.price,
        isFilial: isFilial,
      });

      if (!!response.data.message) {
        notification.success({
          message: `OK`,
          description: response.data.message,
          placement: "topRight",
        });

        setScanCodes([]);
      } else {
        notification.warn({
          message: ``,
          description: "出错",
          placement: "topRight",
        });
      }
      setLoading(false);
      setShowIin(false);
    } catch (error) {
      console.error(error);

      setLoading(false);
      setShowIin(false);
    }
  };

  const handleConfirmRecivedOrder = async () => {
    setShowIin(true);
  };

  // const sendReceiceOrderReq = async () => {
  //   const response = await OrderService.receive({
  //     barCodes: scanedCodes,
  //     // iin: "todo",
  //     iin: form.getFieldValue("iin"),
  //     fee: form.getFieldValue("fee"),
  //     weight: form.getFieldValue("weight"),
  //     currency: setting.currency,
  //     price: setting.price,
  //   });

  //   if (!!response.data.message) {
  //     notification.success({
  //       message: `OK`,
  //       description: response.data.message,
  //       placement: "topRight",
  //     });

  //     setScanCodes([]);
  //   } else {
  //     notification.warn({
  //       message: ``,
  //       description: "出错",
  //       placement: "topRight",
  //     });
  //   }
  // };

  const onFinish = async (values: any) => {
    setLoadingOrder(true);
    const res = await OrderService.clientPreCheck(values);
    setScanCodes((current) => [...current, res.data]);
    setLoadingOrder(false);
    // console.log(scanedCodes);
    // console.log(res.data.barCode);
  };

  const onCheckOrder = async (data: string) => {
    try {
      setLoadingOrder(true);
      const res = await OrderService.clientPreCheck({ barCode: data });
      setScanCodes((current) => [...current, res.data]);
      setLoadingOrder(false);
    } catch (error) {
      setLoadingOrder(false);
    }

    // setLoadingOrder(true);
    // const res = await OrderService.clientPreCheck({
    //   barCode: data,
    // });

    // console.log(res.data.barCode);

    // setScanCodes((current) => [...current, res.data.barCode]);

    // console.log(scanedCodes);

    // setLoadingOrder(false);
  };

  const handleCorrectOrder = async (barCode: string, index: number) => {
    try {
      setFreshing(true);
      const res = await OrderService.clientPreCheck({ barCode: barCode });

      setScanCodes((current) => {
        current[index] = res.data;
        return current;
      });
      // if (res.data.status !== OrderStatus.ARRIVED_AT_KAZAKHSTAN) {
      //   audioRef.current.play();
      //   setFreshing(false);
      //   return;
      // }

      setFreshing(false);
    } catch (error) {
      console.error(error);
      setFreshing(false);
    }
  };
  return (
    <div style={{ width: "100%" }}>
      {" "}
      {showIin === false ? (
        <BarcodeReader
          onError={(err: any) => {
            console.log(err);
          }}
          onScan={(data: string) => onCheckOrder(data)}
          // onScan={async (data: string) => {
          //   setLoadingOrder(true);
          //   const res = await OrderService.clientPreCheck({ barCode: data });
          //   setScanCodes((current) => [...current, res.data]);
          //   setLoadingOrder(false);
          //   // setLoadingOrder(true);
          //   // const res = await OrderService.clientPreCheck({
          //   //   barCode: data,
          //   // });

          //   // console.log(res.data.barCode);

          //   // setScanCodes((current) => [...current, res.data.barCode]);

          //   // console.log(scanedCodes);

          //   // setLoadingOrder(false);
          // }}
        />
      ) : null}
      <Row justify="center" style={{ width: "100%" }}>
        <Col span={16}>
          <Card>
            <Form
              style={{ marginBottom: 16 }}
              name="basic"
              onFinish={(val: any) => onCheckOrder(val.barCode)}
              // onFinish={(values: any) => onFinish(values)}
              layout="inline"
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label=""
                name="barCode"

                // rules={[{ required: true, message: "Пожалуйста, введите длину!!" }]}
              >
                <Input placeholder="条码/штрих-код" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  添加/Добавить
                </Button>
              </Form.Item>
            </Form>
            <List
              loading={loadingOrder}
              header={
                <div>
                  {!isFilial ? (
                    <>
                      <b>条码</b> （填写后，点击《确认》接货）
                      <br />
                      <b>Штрих-код</b> (После заполнения нажмите "Подтвердить"
                      для получения товара)
                    </>
                  ) : (
                    <>
                      {" "}
                      <b>Штрих-код</b> (После заполнения нажмите "Подтвердить"
                      для получения товара)
                    </>
                  )}
                </div>
              }
              footer={
                <div>
                  <Button
                    type="primary"
                    loading={loading}
                    disabled={
                      isFilial === true
                        ? scanedCodes.some(
                            (item: IORDERS) =>
                              ![
                                OrderStatus.RECIEVED_FROM_KZ_INNER,
                                OrderStatus.READY_FOR_PICKUP,
                              ].includes(item.status)
                          )
                        : scanedCodes.some(
                            (item: IORDERS) =>
                              ![
                                OrderStatus.ARRIVED_AT_KAZAKHSTAN,
                                OrderStatus.READY_FOR_PICKUP,
                              ].includes(item.status)
                            // item.status !== OrderStatus.ARRIVED_AT_KAZAKHSTAN
                          )
                    }
                    onClick={() => handleConfirmRecivedOrder()}
                    // onKeyDown={(event) => {
                    //   if (event.key === "Enter") {
                    //     console.log("enter press here! ");
                    //   }
                    // }}
                  >
                    {isFilial ? "Подтвердить" : "OK"}
                  </Button>
                </div>
              }
              //filial todo
              bordered
              dataSource={scanedCodes}
              renderItem={(item, index) => (
                <List.Item
                  actions={[
                    isFilial === true ? (
                      ![
                        OrderStatus.RECIEVED_FROM_KZ_INNER,
                        OrderStatus.READY_FOR_PICKUP,
                      ].includes(item.status) ? (
                        <>
                          <Button
                            key="list-loadmore-edit"
                            type="link"
                            onClick={() => {
                              setOpenEdit({
                                open: true,
                                orderGroupId: item.orderGroup,
                              });
                              //handleCancelBarcode(index);
                            }}
                          >
                            单号状态不符合，请修改 / Неверный статус, исправьте
                          </Button>

                          <Button
                            onClick={() =>
                              handleCorrectOrder(item.barCode, index)
                            }
                            loading={refreshing}
                          >
                            {refreshing ? null : <SyncOutlined />}
                          </Button>
                        </>
                      ) : null
                    ) : ![
                        OrderStatus.ARRIVED_AT_KAZAKHSTAN,
                        OrderStatus.READY_FOR_PICKUP,
                      ].includes(item.status) ? (
                      <>
                        <Button
                          key="list-loadmore-edit"
                          type="link"
                          onClick={() => {
                            setOpenEdit({
                              open: true,
                              orderGroupId: item.orderGroup,
                            });
                            // handleCancelBarcode(index);
                          }}
                        >
                          单号状态不符合，请修改 / Неверный статус, исправьте
                        </Button>

                        <Button
                          onClick={() =>
                            handleCorrectOrder(item.barCode, index)
                          }
                          loading={refreshing}
                        >
                          {refreshing ? null : <SyncOutlined />}
                        </Button>
                      </>
                    ) : null,
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => handleCancelBarcode(index)}
                      danger
                    >
                      <DeleteOutlined />
                    </Button>,
                  ]}
                >
                  <div>
                    {" "}
                    <Typography.Text mark>{index + 1}.</Typography.Text>{" "}
                    {item.barCode}
                  </div>
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
      {openEdit.open &&
        (isFilial === true ? (
          <FilialDetails openEdit={openEdit} setOpenEdit={setOpenEdit} />
        ) : (
          <OrderDetails openEdit={openEdit} setOpenEdit={setOpenEdit} />
        ))}
      <Modal
        title="输入IIN"
        open={showIin}
        onCancel={() => setShowIin(false)}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinishConfirmRecivedOrder}
          autoComplete="off"
        >
          <Form.Item name="iin" label="IIN" rules={[{ required: true }]}>
            <Input placeholder="IIN" />
          </Form.Item>

          {isFilial === false ? (
            <>
              <Form.Item
                name="weight"
                label="重量/Вес"
                rules={[{ required: true }]}
              >
                <InputNumber
                  placeholder="重量/Вес"
                  value={reportInfo?.weight}
                  onChange={(val) => {
                    setReportInfo((current) => {
                      return { ...current, weight: val };
                    });
                    // setting.currency * setting.price * parseFloat(val.toString());
                    if (!!val) {
                      form.setFieldValue(
                        "fee",
                        setting.currency *
                          setting.price *
                          parseFloat(val.toString()) *
                          0.01 +
                          setting.currency *
                            setting.price *
                            parseFloat(val.toString())
                      );
                    }

                    // setReportInfo((current) => {
                    //   return { ...current,  };
                    // });
                    // setReportInfo({ ...reportInfo, weight: val.toString() });
                    // console.log(form.getFieldValue("weight"));
                  }}
                />
              </Form.Item>
              <p>
                {setting.currency}(тг) * {setting.price}($) *{" "}
                {reportInfo?.weight}
                (kg) * 0.1%
              </p>
              <Form.Item
                name="fee"
                label="费用/Платеж"
                rules={[{ required: true }]}
              >
                <InputNumber
                  placeholder="费用/Платеж (тг)"
                  disabled={!reportInfo?.weight}
                  value={reportInfo?.fee}
                  onChange={(val) => {
                    setReportInfo((current) => {
                      return { ...current, fee: val.toString() };
                    });
                    // setReportInfo({ ...reportInfo, weight: val.toString() });
                    // console.log(form.getFieldValue("weight"));
                  }}
                />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item name="weight" label="重量/Вес" hidden>
                <InputNumber />
              </Form.Item>

              <Form.Item name="fee" label="费用/Платеж" hidden>
                <InputNumber />
              </Form.Item>
            </>
          )}

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                OK
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default RecieveBarcode;
