import { BarcodeOutlined, InboxOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

// <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
// Navigation Four - Link
// </a>

export enum BarCodeType {
  CARGO = "CARGO",
  PACKAGE = "PACKAGE",
}

export const CabinetNavigation = [
  {
    key: BarCodeType.PACKAGE,
    icon: BarcodeOutlined,
    // label: `Посылки`,
    label: <Link to={`/cabinet/${BarCodeType.PACKAGE}`}>Посылки</Link>,
  },
  {
    key: BarCodeType.CARGO,
    icon: InboxOutlined,
    label: <Link to={`/cabinet/${BarCodeType.CARGO}`}>Груз</Link>,
  },
];
