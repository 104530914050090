import React, { useEffect, useState } from "react";
import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Breadcrumb, Layout, Menu } from "antd";
import { NavLink, Route, Routes, useLocation } from "react-router-dom";
import Faq from "./faq";
import Story from "./story/Story";
import Photo from "./photo/Photo";
import NoOwnerCode from "./no-owner-code";
import PhotoBarcode from "./photoBarcode/PhotoBarcode";
import PhotoProduct from "./photoProduct/PhotoProduct";
import Product from "./product/Product";
import ProductOrder from "./product-order/ProductOrder";
import Category from "./category";
const { Header, Content, Sider } = Layout;

const items2: MenuProps["items"] = [
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
].map((icon, index) => {
  const key = String(index + 1);

  return {
    key: `sub${key}`,
    icon: React.createElement(icon),
    label: `subnav ${key}`,

    children: new Array(4).fill(null).map((_, j) => {
      const subKey = index * 4 + j + 1;
      return {
        key: subKey,
        label: `option${subKey}`,
      };
    }),
  };
});
const Mobile = () => {
  const location = useLocation();
  const lastPath = location.pathname;
  const [selected, setSelected] = useState("");

  useEffect(() => {
    console.log(lastPath);
    const pathSegments = lastPath.split("/");
    const lastPathSegment = pathSegments[pathSegments.length - 1];

    if (lastPathSegment === "faq") {
      setSelected("faq");
      return;
    }
    if (lastPathSegment === "category") {
      setSelected("category");
      return;
    }
    if (lastPathSegment === "photo") {
      setSelected("photo");
    }
    if (lastPathSegment === "story") {
      setSelected("story");
      return;
    }
    if (lastPathSegment === "photonoownercode") {
      setSelected("photonoownercode");
      return;
    }
    if (lastPathSegment === "noownercode") {
      setSelected("noownercode");
      return;
    }
    if (lastPathSegment === "productphoto") {
      setSelected("productphoto");
      return;
    }
    if (lastPathSegment === "product") {
      setSelected("product");
      return;
    }
    if (lastPathSegment === "productorder") {
      setSelected("productorder");
      return;
    }
  }, [lastPath]);

  return (
    <Layout>
      <Layout>
        <Sider width={200}>
          <Menu
            style={{ height: "100%", borderRight: 0 }}
            selectedKeys={[selected]}

            // selectedKeys={}
          >
            <Menu.Item key="faq">
              {" "}
              <NavLink
                to="/admin/mobile/faq"
                // className={({ isActive }) =>
                //   isActive ? activeClassName : undefined
                // }
              >
                FAQ
              </NavLink>
            </Menu.Item>
            <Menu.Item key="photo">
              {" "}
              <NavLink
                to="/admin/mobile/photo"
                // className={({ isActive }) =>
                //   isActive ? activeClassName : undefined
                // }
              >
                图片
              </NavLink>
            </Menu.Item>
            <Menu.Item key="story">
              {" "}
              <NavLink
                to="/admin/mobile/story"
                // className={({ isActive }) =>
                //   isActive ? activeClassName : undefined
                // }
              >
                快拍
              </NavLink>
            </Menu.Item>
            <Menu.Item key="photonoownercode">
              {" "}
              <NavLink
                to="/admin/mobile/photonoownercode"
                // className={({ isActive }) =>
                //   isActive ? activeClassName : undefined
                // }
              >
                无主条码照片
              </NavLink>
            </Menu.Item>
            <Menu.Item key="noownercode">
              {" "}
              <NavLink
                to="/admin/mobile/noownercode"
                // className={({ isActive }) =>
                //   isActive ? activeClassName : undefined
                // }
              >
                无主条码
              </NavLink>
            </Menu.Item>
            <Menu.Item key="productphoto">
              {" "}
              <NavLink
                to="/admin/mobile/productphoto"
                // className={({ isActive }) =>
                //   isActive ? activeClassName : undefined
                // }
              >
                商品照片
              </NavLink>
            </Menu.Item>
            <Menu.Item key="product">
              {" "}
              <NavLink
                to="/admin/mobile/product"
                // className={({ isActive }) =>
                //   isActive ? activeClassName : undefined
                // }
              >
                商品
              </NavLink>
            </Menu.Item>
            <Menu.Item key="category">
              {" "}
              <NavLink
                to="/admin/mobile/category"
                // className={({ isActive }) =>
                //   isActive ? activeClassName : undefined
                // }
              >
                商品类别
              </NavLink>
            </Menu.Item>
            <Menu.Item key="productorder">
              {" "}
              <NavLink
                to="/admin/mobile/productorder"
                // className={({ isActive }) =>
                //   isActive ? activeClassName : undefined
                // }
              >
                商品订单
              </NavLink>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout style={{ padding: "0 24px 24px", height: "100%" }}>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            <Routes>
              <Route path="faq" element={<Faq />} />
              <Route path="story" element={<Story />} />
              <Route path="photo" element={<Photo />} />
              <Route path="photonoownercode" element={<PhotoBarcode />} />
              <Route path="noownercode" element={<NoOwnerCode />} />
              <Route path="productphoto" element={<PhotoProduct />} />
              <Route path="product" element={<Product />} />
              <Route path="productorder" element={<ProductOrder />} />
              <Route path="category" element={<Category />} />
              {/* <Route path="noownercode" element={<NoOwnerCode />} /> */}
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Mobile;
