import { ClockCircleOutlined, NotificationOutlined } from "@ant-design/icons";
import { Card, Timeline } from "antd";
import React from "react";
import { formatDate } from "../../../utils/notificationUtil";
import { OrderStatus } from "../home/statusOrder";
import { TimelineInterface } from "./OrderDetails";
interface props {
  timeline: TimelineInterface[];
  message_ru: boolean;
}

function TimelineOrder({ timeline, message_ru }: props) {
  return (
    <Card style={{ overflow: "auto", width: "100%" }}>
      <Timeline
        mode={"left"}
        pending={timeline.length === 0 ? "Ожидание импорта трек-кода" : null}
      >
        {timeline.map((time: TimelineInterface) => (
          <Timeline.Item
            label={formatDate(time.createdAt)}
            key={time._id}
            dot={
              time.status === OrderStatus.SENT_FROM_CHINA ||
              time.status === OrderStatus.ARRIVED_AT_KAZAKHSTAN ? (
                <NotificationOutlined
                  className="timeline-clock-icon"
                  style={{
                    color: `${
                      time.status === OrderStatus.SENT_FROM_CHINA
                        ? "#e76f51"
                        : "currentColor"
                    }`,
                  }}
                />
              ) : (
                <NotificationOutlined className="timeline-clock-icon" />
              )
            }
            color={
              time.status === OrderStatus.SENT_FROM_CHINA ||
              time.status === OrderStatus.ARRIVED_AT_KAZAKHSTAN
                ? "#00CCFF"
                : "grey"
            }
          >
            {message_ru ? time.message_ru : time.message}
          </Timeline.Item>
        ))}
      </Timeline>
    </Card>
  );
}

export default TimelineOrder;
