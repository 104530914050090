import React, { useEffect } from "react";
import { Button, Card, Form, Input, Modal } from "antd";
import Cargo from "../../../img/cargo.jpg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  checkIn,
  selectLoading,
  selectRedirect,
  selectToken,
  selectVerified,
  showLoading,
} from "../../../redux/slices/customerSlice";
import ConfirmModal from "../../../components/confirmModal";
import MaskedInput from "antd-mask-input/build/main/lib/MaskedInput";
function CheckIn() {
  let history = useNavigate();
  const verfied = useSelector(selectVerified);

  const token = useSelector(selectToken);

  const loading = useSelector(selectLoading);

  useEffect(() => {
    console.log(verfied);
    console.log(token);

    if (verfied === true && token !== "") {
      history("/cabinet");
    }
  }, [token, verfied]);
  // const accessToken = useSelector(selectToken);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    dispatch(showLoading());
    dispatch(checkIn(values));
  };
  return (
    <div className="phoneConfirm">
      <Card>
        <img src={Cargo} alt="Cargo" width={250} />
        <Form
          form={form}
          layout="vertical"
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Номер телефон"
            name="telephone"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите свой номер телефона!",
              },
            ]}
          >
            <MaskedInput
              mask={
                //  https://imask.js.org/guide.html#masked-pattern
                "+7(000)000-00-00"
              }
            />
          </Form.Item>

          <Form.Item
            label={"Пароль"}
            name="password"
            rules={[{ required: true, message: "Пожалуйста, введите пароль!" }]}
          >
            <Input.Password className="login_input" />
          </Form.Item>

          <Form.Item>
            <Button type="link" htmlType="button">
              <Link to={"/register"}>Регистрация</Link>
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Личный кабинет
            </Button>
          </Form.Item>
          <Button style={{ width: "100%" }} type="link" htmlType="button">
            <Link style={{ textAlign: "center" }} to={"/forgetpassword"}>
              Забыли пароль
            </Link>
          </Button>
        </Form>
      </Card>

      <ConfirmModal />
    </div>
  );
}

export default CheckIn;
