import { Button, Card, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PricelistService from "../../../service/PricelistService";
import AddPriceList from "./AddPriceList";
import EditPriceList from "./EditPriceList";

function PriceList() {
  const [openAdd, setOpenAdd] = useState(false);

  const [loading, setLoading] = useState(false);

  const [openEdit, setOpenEdit] = useState({
    open: false,
    priceListId: "",
  });
  const [priceList, setPriceList] = useState<any>([]);

  const { cargoType } = useParams();

  const showDrawerAdd = () => {
    setOpenAdd(true);
  };

  const showDrawerEdit = (record: any) => {
    setOpenEdit({ open: true, priceListId: record._id });
  };

  useEffect(() => {
    const fetchOrders = async () => {
      if (cargoType) {
        setLoading(true);
        const result = await PricelistService.fetchFilter(1, cargoType);
        setPriceList({
          data: result.data.priceLists,
          total: result.data.total,
        });
        setLoading(false);
      }
    };
    fetchOrders();
  }, [cargoType]);

  const columns = [
    // {
    //   title: "品名",
    //   dataIndex: "key",
    //   key: "key",
    // },
    {
      title: "密度(kg/m)",
      dataIndex: "density",
      key: "density",
      render: (text: string, record: any) => (
        <span>
          {record.density_from} - {record.density_to}
        </span>
      ),
    },
    {
      title: "快运",
      dataIndex: "express_plan",
      key: "express_plan",
    },
    {
      title: "火车运",
      dataIndex: "train_plan",
      key: "train_plan",
    },
    {
      title: "飞机运",
      dataIndex: "plane_plan",
      key: "plane_plan",
    },
    {
      title: "标准运",
      dataIndex: "standard_plan",
      key: "standard_plan",
    },
    {
      title: "更多",
      key: "action",
      render: (text: string, record: any) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              showDrawerEdit(record);
            }}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  const pageChangeHandle = async (page: number, pageSize: number) => {
    if (cargoType) {
      setLoading(true);
      const res = await PricelistService.fetchFilter(page, cargoType);
      setPriceList({
        data: res.data.priceLists,
        total: res.data.total,
      });
      setLoading(false);
    }
  };

  return (
    <Card>
      <Button
        onClick={showDrawerAdd}
        style={{
          marginTop: 20,
          marginBottom: 20,
          marginLeft: 20,
          backgroundColor: "green",
        }}
        type="primary"
      >
        添加
      </Button>
      <Table
        loading={loading}
        dataSource={priceList.data}
        columns={columns}
        rowKey="_id"
        pagination={{
          pageSize: 12,
          total: priceList.total,
          onChange: pageChangeHandle,
        }}
      />
      {openAdd && <AddPriceList openData={openAdd} setOpenData={setOpenAdd} />}
      {openEdit.open && (
        <EditPriceList openData={openEdit} setOpenData={setOpenEdit} />
      )}
    </Card>
  );
}

export default PriceList;
