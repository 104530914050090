import { Button, Card, Input, Modal, Row, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CategoryService from "../../../../service/CategoryService";
// import AddCategory from "./AddCategory";
// import EditCategory from "./EditCategory";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import AddCategory from "./AddCategory";
import EditCategory from "./EditCategory";

const { confirm } = Modal;
const { Search } = Input;
const Category = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(false);

  const [openEdit, setOpenEdit] = useState({
    open: false,
    CategoryId: "",
  });
  const [Category, setCategory] = useState<any>([]);

  const showDrawerAdd = () => {
    setOpenAdd(true);
  };

  const showDrawerEdit = (record: any) => {
    setOpenEdit({ open: true, CategoryId: record._id });
  };
  const fetchOrders = async () => {
    setLoading(true);
    const result = await CategoryService.fetchFilter(1, 12, search);

    setCategory({
      data: result.data.categories,
      total: result.data.total,
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const columns = [
    // {
    //   title: "品名",
    //   dataIndex: "key",
    //   key: "key",
    // },
    {
      title: "名称RU",
      dataIndex: "title_ru",
      key: "title_ru",
      // width: "70%",
    },
    {
      title: "名称CN",
      dataIndex: "title_zh",
      key: "title_zh",
      // width: "70%",
    },

    {
      title: "更多",
      key: "action",

      render: (text: string, record: any) => (
        <>
          <Space size="middle">
            <Button
              danger
              type="primary"
              onClick={() => {
                deleteCategoryConfirm(record._id);
              }}
            >
              删除
            </Button>
          </Space>
          <Space size="middle">
            <Button
              type="primary"
              onClick={() => {
                showDrawerEdit(record);
              }}
            >
              修改
            </Button>
          </Space>
        </>
      ),
    },
  ];

  const pageChangeHandle = async (page: number, pageSize: number) => {
    setLoading(true);
    const res = await CategoryService.fetchFilter(page, 12, search);
    setCategory({
      data: res.data.categories,
      total: res.data.total,
    });
    setLoading(false);
  };

  const deleteCategory = async (id: string) => {
    await CategoryService.delete(id);
    window.location.reload();
  };

  const deleteCategoryConfirm = (id: string) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "确认删除？",
      okText: "确认",
      cancelText: "取消",
      onOk() {
        deleteCategory(id);
      },
    });
  };

  const onSearch = async (value: string) => {
    setLoading(true);
    fetchOrders();
    setLoading(false);
  };
  return (
    <Card>
      <Row>
        <Button
          onClick={showDrawerAdd}
          style={{
            marginTop: 20,
            marginBottom: 20,
            marginLeft: 20,
            backgroundColor: "green",
          }}
          type="primary"
        >
          添加
        </Button>
        {/* <Search
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder="条码"
          enterButton="搜索"
          size="large"
          loading={false}
          onSearch={onSearch}
        /> */}
      </Row>

      <Table
        loading={loading}
        dataSource={Category.data}
        columns={columns}
        rowKey="_id"
        pagination={{
          pageSize: 12,
          total: Category.total,
          onChange: pageChangeHandle,
        }}
      />
      {openAdd && <AddCategory openData={openAdd} setOpenData={setOpenAdd} />}
      {openEdit.open && (
        <EditCategory openData={openEdit} setOpenData={setOpenEdit} />
      )}
    </Card>
  );
};

export default Category;
