import { notification } from "antd";

type NotificationType = "success" | "info" | "warning" | "error";

export const openNotificationWithIcon = (
  type: NotificationType,
  msg: string,
  description: string
) => {
  notification[type]({
    message: msg,
    description: description,
    placement: "bottomRight",
  });
};

export const formatDate = (text: string) => {
  const date = new Date(text);
  return (
    date.toLocaleDateString() +
    " " +
    date.toLocaleTimeString([], {
      hour12: false,
    })
  );
};
