import { useEffect } from "react";
import fetch from "../auth/FetchInterceptor";
import { PLAN_TYPE } from "./OrderService";

// export interface qrCodeDataInterface {
//   orderId: string;
//   qrType: string;
//   qrId: string;
//   warehouse: string;
// }

export interface calculatorInterface {
  weight: number;
  length: number;
  height: number;
  width: number;
  title: string;
  plan: PLAN_TYPE;
  cargo_type: CargoType;
}

export enum CargoType {
  CLOTH = "CLOTH",
  TOY = "TOY",
  BIG_CARGO = "BIG_CARGO",
  SMALL_CARGO = "SMALL_CARGO",
}
export enum CargoTypeColor {
  CLOTH = "magenta",
  TOY = "orange",
  BIG_CARGO = "green",
  SMALL_CARGO = "geekblue",
}

export const CargoTypeTextData = [
  {
    key: CargoType.TOY,
    text_cn: "玩具",
    text_ru: "Игрушки",
  },
  {
    key: CargoType.CLOTH,
    text_cn: "衣服",
    text_ru: "Одежда",
  },
  {
    key: CargoType.BIG_CARGO,
    text_cn: "大百货",
    text_ru: "Смешка",
  },
  {
    key: CargoType.SMALL_CARGO,
    text_cn: "小百货",
    text_ru: "Хоска",
  },
];

const ProductOrderService = {
  fetchFilter: function (page: number, limit = 12, search = "") {
    return fetch({
      url: `/productOrder-filter?page=${page}&limit=${limit}&search=${search}`,
      method: "get",
      headers: {
        "public-request": "true",
      },
    });
  },
  update: function (data: any, id: string) {
    return fetch({
      url: `/productOrder/${id}`,
      method: "put",
      data: data,
      headers: {
        "public-request": "true",
      },
    });
  },
  getOne: function (data: string) {
    return fetch({
      url: `/productOrder/${data}`,
      method: "get",
      headers: {
        "public-request": "true",
      },
    });
  },

  add: function (data: any) {
    return fetch({
      url: `/productOrder-add`,
      method: "post",
      data: data,
      headers: {
        "public-request": "true",
      },
    });
  },

  delete: function (data: string) {
    return fetch({
      url: `/productOrder/${data}`,
      method: "delete",
      headers: {
        "public-request": "true",
      },
    });
  },
};

export default ProductOrderService;
