import { createSlice } from "@reduxjs/toolkit";
import { REGISTER_TEL } from "../constants/Auth";

export const getAuthState = () => {
  const auth = localStorage.getItem(REGISTER_TEL) || "{}";
  if (!!auth) {
    try {
      const authobj = JSON.parse(auth);
      return authobj;
    } catch (error) {
      console.error(error);
    }
  }
};
const authLocalState = getAuthState(); // token and expireDate

export const customerSlice = createSlice({
  name: "customer",
  initialState: {
    _id: authLocalState._id || "",
    telephone: authLocalState.telephone || "",
    name: authLocalState.name || "",
    verified: authLocalState.verified || false,
    loading: false,
    accessToken: authLocalState?.accessToken || "",
    refreshToken: authLocalState?.refreshToken || "",
    redirect:
      authLocalState && authLocalState.verified === true
        ? "/cabinet"
        : "/register",
    message: "",
    isModalShown: false,
  },
  reducers: {
    checkIn: (state, payload) => state,

    verify: (state, payload) => state,

    register: (state, action) => state,

    registerSuccess: (state, action) => {
      return {
        ...state,
        _id: action.payload._id,
        telephone: action.payload.telephone,
        name: action.payload.name,
        verified: action.payload.verified,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        loading: false,
        message: "",
      };
    },

    signOut: (state) => state,

    authenticated: (state, action) => {
      return {
        ...state,
        _id: action.payload.customer._id,
        telephone: action.payload.customer.telephone,
        name: action.payload.customer.name,
        verified: action.payload.customer.verified,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        loading: false,
        redirect: "/cabinet",
        message: "",
      };
    },

    showAuthMessage: (state, action) => {
      return {
        ...state,
        message: action.payload,
        showMessage: true,
        loading: false,
      };
    },

    showModal: (state, action) => {
      return {
        ...state,
        isModalShown: action.payload.isModalShown,
        loading: false,
      };
    },

    hideAuthMessage: (state) => {
      return { ...state, message: "", showMessage: false };
    },

    signOutSuccess: (state) => {
      return {
        ...state,
        _id: "",
        telephone: "",
        name: "",
        verified: false,
        loading: false,
        redirect: "/checkIn",
        message: "",
        accessToken: "",
        refreshToken: "",
      };
    },

    // signUpSuccess: (state) => {
    //   return { ...state, loading: false };
    // },

    showLoading: (state) => {
      return { ...state, loading: true };
    },
    closeLoading: (state) => {
      return { ...state, loading: false };
    },
  },
});

export const selectLoading = (state: any) => state.customer.loading;
export const selectMessage = (state: any) => state.customer.message;
export const selectTelephone = (state: any) => state.customer.telephone;
export const selectId = (state: any) => state.customer._id;
export const selectName = (state: any) => state.customer.name;
export const selectVerified = (state: any) => state.customer.verified;
export const selectIsModalShown = (state: any) => state.customer.isModalShown;
export const selectRedirect = (state: any) => state.customer.redirect;
export const selectToken = (state: any) => state.customer.accessToken;

export const {
  checkIn,
  verify,
  register,
  signOut,
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  closeLoading,
  showModal,
  registerSuccess,
} = customerSlice.actions;
export default customerSlice.reducer;
