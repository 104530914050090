import {
  handleSignIn,
  handleSignUp,
  handleSignOut,
  //handleUpdateContact,
} from "../handler/userHandler";
import { signIn, signOut, signUp } from "../../slices/userSlice";
import { all, takeLatest } from "redux-saga/effects";
export default function* userSaga(): Generator<any, any, any> {
  yield all([
    yield takeLatest(signIn.type, handleSignIn),
    yield takeLatest(signUp.type, handleSignUp),
    yield takeLatest(signOut.type, handleSignOut),
    //yield takeLatest(updateUserInfo.type, handleUpdateContact),
  ]);
}
