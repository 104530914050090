import { Button, Drawer, Form, InputNumber, Spin, notification } from "antd";
import { NotificationPlacement } from "antd/lib/notification";
import React, { useEffect, useRef, useState } from "react";
import SettingService from "../../../../service/SettingService";

const EditSetting = ({ openData, setOpenData }: any) => {
  const formRef = useRef<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSetting = async () => {
      setLoading(true);
      const response = await SettingService.getOne(openData.settingId);

      formRef.current.setFieldsValue({
        currency: response.data.currency,
        price: response.data.price,
      });
      setLoading(false);
    };
    fetchSetting();
  }, []);

  const openNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: `Success`,
      description: "更改成功",
      placement,
    });
  };
  const onClose = () => {
    setOpenData({ open: false, settingId: "" });
  };
  const onFinish = async (values: any) => {
    setLoading(true);

    // _id: response.data._id,
    //     titleRU: response.data.titleRU,
    //     titleKZ: response.data.titleKZ,
    //     isMain: response.data.isMain,

    await SettingService.update(values, openData.settingId);
    openNotification("topRight");
    setLoading(false);
    window.location.reload();
  };

  return (
    <Drawer
      title="修改"
      placement="right"
      onClose={onClose}
      open={openData.open}
      width={"50%"}
    >
      <Spin size="large" spinning={loading}>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          ref={formRef}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="汇率/курс обмена" name="currency" required>
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="一公斤价格（阿拉木图）/ Цена за килограмм (Алматы)"
            name="price"
            required
          >
            <InputNumber />
          </Form.Item>
          <Form.Item

          // wrapperCol={{ offset: 8, span: 16 }}
          >
            <Button type="primary" htmlType="submit">
              添加
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default EditSetting;
