import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  notification,
  Pagination,
  Select,
  Space,
  Spin,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { NotificationPlacement } from "antd/lib/notification";
import React, { useEffect, useState } from "react";
import CategoryService from "../../../../service/CategoryService";
import TextArea from "antd/lib/input/TextArea";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";
import {
  API_IMG_NO_OWNER_URL,
  API_IMG_PRODUCT,
} from "../../../../configs/AppConfig";

function AddCategory({ openData, setOpenData }: any) {
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState<any>([]);
  const [selectLoading, setSelectLoading] = useState(false);

  const openNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: `Success`,
      description: "添加成功",
      placement,
    });
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    console.log(values);
    await CategoryService.add({
      ...values,
    });
    openNotification("topRight");
    setLoading(false);
    window.location.reload();
  };

  const onClose = () => {
    setOpenData(false);
  };

  return (
    <Drawer
      title={`添加商品类别`}
      placement="right"
      onClose={onClose}
      open={openData}
      width={"50%"}
    >
      <Spin spinning={loading} size="large">
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="名称RU" name="title_ru" required>
            <Input />
          </Form.Item>
          <Form.Item label="名称KZ" name="title_kk" required>
            <Input />
          </Form.Item>
          <Form.Item label="名称CN" name="title_zh" required>
            <Input />
          </Form.Item>

          <Form.Item
            wrapperCol={{ offset: 8, span: 16 }}

            // wrapperCol={{ offset: 8, span: 16 }}
          >
            <Button type="primary" htmlType="submit">
              添加
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default AddCategory;
