import fetch from "../auth/FetchInterceptor";
const CalculatorService = {
  fetchFilter: function (page: number, limit = 12) {
    return fetch({
      url: `/calculators-filter?page=${page}&limit=${limit}`,
      method: "get",
      headers: {
        "public-request": "true",
      },
    });
  },
};

export default CalculatorService;
