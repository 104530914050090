import { Button, Card, Checkbox, Form, Input, Modal } from "antd";
import { MaskedInput } from "antd-mask-input";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ConfirmModal from "../../../components/confirmModal";

import Cargo from "../../../img/cargo.jpg";
import {
  register,
  selectLoading,
  selectRedirect,
  selectToken,
  selectVerified,
  showLoading,
} from "../../../redux/slices/customerSlice";

function PhoneConfirm() {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const [form] = Form.useForm();
  // const redirect_cabinet = useSelector(selectRedirect);
  const verfied = useSelector(selectVerified);
  const token = useSelector(selectToken);

  let history = useNavigate();

  useEffect(() => {
    if (verfied === true && token !== "") {
      history("/cabinet");
    }
  }, [verfied, token]);

  useEffect(() => {
    document.body.classList.add("background-phone-register");
  }, []);

  const OnRegister = (values: any) => {
    dispatch(register(values));
  };

  return (
    <div className="phoneConfirm">
      <Card>
        <img
          src={Cargo}
          alt="Cargo"
          width={250}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
          }}
        />
        <Form
          form={form}
          layout="vertical"
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={OnRegister}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Номер телефон"
            name="telephone"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите свой номер телефона!",
              },
            ]}
          >
            <MaskedInput
              mask={
                //  https://imask.js.org/guide.html#masked-pattern
                "+7(000)000-00-00"
              }
            />
          </Form.Item>

          <Form.Item
            label="Имя на английском языке"
            name="name"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите свое имя на английском языке!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={"Пароль"}
            name="password"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите пароль!",
                min: 6,
              },
            ]}
          >
            <Input.Password className="login_input" />
          </Form.Item>

          <Form.Item>
            <Button type="link" htmlType="button" style={{ marginRight: 10 }}>
              <Link to={"/checkin"}>Уже зарегистрировались</Link>
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Зарегистрировать
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <ConfirmModal />
      {/* {verfied === false && <Navigate replace to={"/checkin"} />} */}
    </div>
  );
}

export default PhoneConfirm;
