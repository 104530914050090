import { BarCodeType } from "../../../configs/CabinetNavigation";

export interface ICabinet {
  barCode: string;
  status: string;
  createdAt: string | Date;
  customer: string;
  _id: string;
  id: string;
  type: BarCodeType;
  updatedAt: string | Date;
}

export enum CABINET_ORDER_STATAUS {
  IN_KAZAKHSTAN = "IN_KAZAKHSTAN",
  DELIVERED = "DELIVERED",
  IN_CHINA = "IN_CHINA",
  WAITTING = "WAITTING",
}
