import { Drawer, Spin } from "antd";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import OrderService from "../../../service/OrderService";
import TimelineOrder from "../order-details/TimelineOrder";

interface openEditInterface {
  open: boolean;
  orderId: string;
}

interface props {
  openEdit: openEditInterface;
  setOpenEdit: Dispatch<SetStateAction<openEditInterface>>;
}
function OrderTimeLine({ openEdit, setOpenEdit }: props) {
  const [timeline, setTimeline] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchTimline() {
      setLoading(true);
      const res = await OrderService.fetchTimeline(openEdit.orderId);
      setTimeline(res.data);
      setLoading(false);
    }
    fetchTimline();
  }, []);

  const onClose = () => {
    setOpenEdit({ ...openEdit, open: false });
  };

  return (
    <Drawer
      title={`订单详情 【${openEdit.orderId}】`}
      placement="right"
      onClose={onClose}
      width={600}
      open={openEdit.open}
    >
      <Spin spinning={loading} size="large">
        <div className="order_details">
          <TimelineOrder timeline={timeline} message_ru={false} />
        </div>
      </Spin>
    </Drawer>
  );
}

export default OrderTimeLine;
