import React, { useEffect } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  hideAuthMessage,
  selectLoading,
  selectShowMessage,
  selectToken,
  showLoading,
  signIn,
} from "../../../redux/slices/userSlice";
import { useLocation, useNavigate } from "react-router-dom";

function Login() {
  let history = useNavigate();

  // const {
  //   otherSignIn,
  //   showForgetPassword,
  //   onForgetPasswordClick,
  //   extra,
  //   allowRedirect,
  // } = props;

  const loading = useSelector(selectLoading);
  const accessToken = useSelector(selectToken);
  const showMessage = useSelector(selectShowMessage);
  const location = useLocation();

  const dispatch = useDispatch();

  const onLogin = (values: any) => {
    dispatch(showLoading());
    dispatch(signIn(values));
  };

  useEffect(() => {
    const redirect = sessionStorage.getItem("redirect");

    if (!!accessToken) {
      if (!!redirect) {
        history("../admin/filial", { replace: true });
      } else {
        history("../admin", { replace: true });
      }
    }
    if (showMessage) {
      setTimeout(() => {
        hideAuthMessage();
      }, 3000);
    }
  }, [accessToken]);

  const onFinishFailed = (errorInfo: any) => {
    console.error("Failed:", errorInfo);
  };

  return (
    <div className="login_wrapper">
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onLogin}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="remember"
          valuePropName="checked"
          wrapperCol={{ offset: 8, span: 16 }}
        >
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Login;
