import { useEffect } from "react";
import fetch from "../auth/FetchInterceptor";
import { PLAN_TYPE } from "./OrderService";

// export interface qrCodeDataInterface {
//   orderId: string;
//   qrType: string;
//   qrId: string;
//   warehouse: string;
// }

const SettingService = {
  fetchFilter: function (page: number, limit = 12) {
    return fetch({
      url: `/setting-filter?page=${page}&limit=${limit}`,
      method: "get",
      headers: {
        "public-request": "true",
      },
    });
  },
  update: function (data: any, id: string) {
    return fetch({
      url: `/setting-update/${id}`,
      method: "put",
      data: data,
      headers: {
        "public-request": "true",
      },
    });
  },
  getOne: function (data: string) {
    return fetch({
      url: `/setting-getOne/${data}`,
      method: "get",
      headers: {
        "public-request": "true",
      },
    });
  },

  init: function () {
    return fetch({
      url: `/setting-init`,
      method: "get",
      headers: {
        "public-request": "true",
      },
    });
  },

  add: function (data: any) {
    return fetch({
      url: `/setting-add`,
      method: "post",
      data: data,
      headers: {
        "public-request": "true",
      },
    });
  },

  delete: function (data: string) {
    return fetch({
      url: `/setting-delete/${data}`,
      method: "delete",
      headers: {
        "public-request": "true",
      },
    });
  },
};

export default SettingService;
