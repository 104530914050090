import {
  Button,
  Card,
  Space,
  Tag,
  Table,
  Modal,
  Spin,
  Form,
  Input,
  Row,
  Typography,
  List,
} from "antd";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

import { ColumnsType } from "antd/lib/table";

import BarcodeReader from "react-barcode-reader";

import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import OrderService from "../../../service/OrderService";
import { OrderStatus } from "../home/statusOrder";
import {
  getOrderStatusEnumText,
  getOrderStatusEnumTextRus,
} from "../../../utils/getEnumText";
import { openNotificationWithIcon } from "../../../utils/notificationUtil";
import { useNavigate } from "react-router-dom";
import history from "../../../history";
import { FILIAL_LOCAL_STORAGE_KEY } from "../../../configs/AppConfig";

interface DataType {
  orderData: orderType[];
  total: number;
}

interface orderType extends BaseOrderType {
  client_name: string;
  client_tel: string;
  status: OrderStatus;
  title: string;
  iin?: string;
}

interface BaseOrderType {
  _id: string;
  orders: number;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  recivedOrderCount: number;
}
interface openEditInterface {
  open: boolean;
  orderGroupId: string;
}

interface props {
  openEdit: openEditInterface;
  setOpenEdit: Dispatch<SetStateAction<openEditInterface>>;
  scanBarcodeMode: boolean;
  setScanBarCodeMode: Dispatch<SetStateAction<boolean>>;
  orderGroup: {
    inKazakhstan: boolean;
    title: string;
    status: string;
    _id: string;
  };
  //   setOpenEdit: Dispatch<SetStateAction<openEditInterface>>;
}

interface IORDERS {
  _id: string;
  status: OrderStatus;
  barCode: string;
  orderGroup: string;
}
const { confirm } = Modal;

const UpdateOrderBarCode = ({
  openEdit,
  setOpenEdit,
  orderGroup,
  scanBarcodeMode,
  setScanBarCodeMode,
}: props) => {
  const [orderLoading, setOrderLoading] = useState(true);

  const [refreshing, setFreshing] = useState(false);
  const [addLoading, setAddloaing] = useState(false);
  const [scanedCodes, setScanCodes] = useState<IORDERS[]>([]);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<DataType>({
    orderData: [],
    total: 0,
  });

  const audioRef: any = useRef();
  const listRef = useRef();

  const fecthExistingOrderinLocalstorage = () => {
    const fillial_existing_orders = localStorage.getItem(
      FILIAL_LOCAL_STORAGE_KEY
    );

    if (!!fillial_existing_orders) {
      const fillial_existing_orders_json: {
        orderGroupId: string;
        barCode: IORDERS[];
      }[] = JSON.parse(fillial_existing_orders);
      console.log(fillial_existing_orders_json);

      let order_index = fillial_existing_orders_json.findIndex(
        (elem) => elem.orderGroupId === openEdit.orderGroupId
      );

      return {
        index: order_index,
        currentOrderGroup: fillial_existing_orders_json[order_index],
        all: fillial_existing_orders_json,
      };
    }
    return null;
  };

  useEffect(() => {
    const fetchOrder = async () => {
      setOrderLoading(true);

      const result = await OrderService.fetchOrder(openEdit.orderGroupId, 1);

      setOrders({ orderData: result.data.orders, total: result.data.total });

      setOrderLoading(false);
    };

    const fillial_existing_orders = fecthExistingOrderinLocalstorage();
    if (
      !!fillial_existing_orders &&
      !!fillial_existing_orders.currentOrderGroup
    ) {
      setScanCodes((current) => [
        ...current,
        ...fillial_existing_orders.currentOrderGroup.barCode,
      ]);
    }

    fetchOrder();
  }, [openEdit.orderGroupId]);

  const deleteOrder = async (id: string) => {
    const res = await OrderService.delete(id);

    const newOrderFilter = orders.orderData.filter((item) => {
      return item._id !== res.data;
    });

    setOrders({
      orderData: newOrderFilter,
      total: orders.total - 1,
    });
  };

  const deleteOrderConfirm = (id: string) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Вы уверены, что хотите удалить штрих-код?",
      okText: "Да",
      cancelText: "Нет",
      onOk() {
        deleteOrder(id);
      },
    });
  };

  const columns: ColumnsType<orderType> = [
    {
      title: "штрих-код",
      dataIndex: "barCode",
      key: "name",
      render: (text) => <b>{text}</b>,
    },

    {
      title: "Статус",
      key: "status",
      dataIndex: "status",
      render: (text: string, record: orderType) => {
        const data = getOrderStatusEnumTextRus(record.status);
        if (data) {
          return <Tag color={data.color}>{data.text}</Tag>;
        }
      },
    },
    {
      title: "IIN",
      key: "iin",
      dataIndex: "iin",
    },
    {
      title: "Более",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            danger
            onClick={() => deleteOrderConfirm(record._id)}
            //   onClick={() => openOrderTimeline(record)}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const orderPageChangeHandle = async (page: number, pageSize: number) => {
    setOrderLoading(true);
    const res = await OrderService.fetchOrder(openEdit.orderGroupId, page);
    setOrders({
      orderData: res.data.orders,
      total: res.data.total,
    });
    setOrderLoading(false);
  };
  const onFinish = (values: any) => {
    OrderService.scanAndUpdateOrder({
      barCode: values.barCode,
      orderGroup: openEdit.orderGroupId,
    })
      .then((res: any) => {
        setAddloaing(false);
        if (!!res.data) {
          openNotificationWithIcon("success", `已添加${res.data.barCode}`, "");

          setOrders({
            orderData: [res.data, ...orders.orderData],
            total: orders.total + 1,
          });
        } else {
          openNotificationWithIcon("error", res.msg, "");
        }
      })
      .catch((err) => {
        console.error(err);
        setAddloaing(false);

        if (err.response.status === 422) {
          openNotificationWithIcon("error", `条码已存在`, "");
          return;
        }
        openNotificationWithIcon("error", err.response.msg, "");
      });
  };

  const onCheckOrder = async (data: string) => {
    try {
      setLoadingOrder(true);
      const res = await OrderService.clientPreCheck({ barCode: data });
      if (res.data.status !== OrderStatus.ARRIVED_AT_KAZAKHSTAN) {
        audioRef.current.play();
      }
      setScanCodes((current) => [res.data, ...current]);

      let newBarcode = [];
      const fillial_existing_orders = fecthExistingOrderinLocalstorage();
      let newAll: {
        orderGroupId: string;
        barCode: IORDERS[];
      }[] = [];

      if (
        fillial_existing_orders &&
        fillial_existing_orders.all.length > 0 &&
        fillial_existing_orders.index > -1
      ) {
        newAll = fillial_existing_orders.all;
        newBarcode = [res.data, ...scanedCodes];
        newAll[fillial_existing_orders.index] = {
          orderGroupId: openEdit.orderGroupId,
          barCode: newBarcode,
        };
      } else if (
        fillial_existing_orders &&
        fillial_existing_orders.all.length > 0 &&
        fillial_existing_orders.index === -1
      ) {
        newAll = [
          ...fillial_existing_orders.all,
          {
            orderGroupId: openEdit.orderGroupId,
            barCode: [res.data, ...scanedCodes],
          },
        ];
      } else {
        newAll = [
          {
            orderGroupId: openEdit.orderGroupId,
            barCode: [res.data, ...scanedCodes],
          },
        ];
      }

      localStorage.setItem(FILIAL_LOCAL_STORAGE_KEY, JSON.stringify(newAll));

      setLoadingOrder(false);
    } catch (error) {
      audioRef.current.play();
      setLoadingOrder(false);
    }

    // setLoadingOrder(true);
    // const res = await OrderService.clientPreCheck({
    //   barCode: data,
    // });

    // console.log(res.data.barCode);

    // setScanCodes((current) => [...current, res.data.barCode]);

    // console.log(scanedCodes);

    // setLoadingOrder(false);
  };

  const handleCancelBarcode = (indexToDelete: number) => {
    setScanCodes((current) => {
      return current.filter(function (value, index, arr) {
        return index !== indexToDelete;
      });
    });

    let newBarcode = [];
    const fillial_existing_orders = fecthExistingOrderinLocalstorage();
    let newAll: {
      orderGroupId: string;
      barCode: IORDERS[];
    }[] = [];
    if (fillial_existing_orders) {
      newAll = fillial_existing_orders.all;
      newBarcode = scanedCodes.filter(function (value, index, arr) {
        return index !== indexToDelete;
      });
      newAll[fillial_existing_orders.index] = {
        orderGroupId: openEdit.orderGroupId,
        barCode: newBarcode,
      };
    }

    localStorage.setItem(FILIAL_LOCAL_STORAGE_KEY, JSON.stringify(newAll));
  };

  const handleSubmitOrderBarcode = async () => {
    setAddloaing(true);
    try {
      const res = await OrderService.scanAndUpdateOrderList({
        barCodes: scanedCodes.map((item) => item.barCode),
        orderGroup: openEdit.orderGroupId,
      });
      setAddloaing(false);

      const fillial_existing_orders = fecthExistingOrderinLocalstorage();
      let newAll: {
        orderGroupId: string;
        barCode: IORDERS[];
      }[] = [];

      if (
        fillial_existing_orders &&
        fillial_existing_orders.all.length > 0 &&
        fillial_existing_orders.index > -1
      ) {
        newAll = fillial_existing_orders.all;

        newAll[fillial_existing_orders.index] = {
          orderGroupId: openEdit.orderGroupId,
          barCode: [],
        };
        setScanCodes([]);
      }

      if (!!res.data.order_invalid) {
        setScanBarCodeMode(true);
        if (!!fillial_existing_orders) {
          newAll[fillial_existing_orders.index] = {
            orderGroupId: openEdit.orderGroupId,
            barCode: res.data.order_invalid,
          };
        }

        setScanCodes(res.data.order_invalid);

        openNotificationWithIcon("success", `部分已添加，请检查剩余条码`, "");
        audioRef.current.play();
      } else {
        openNotificationWithIcon("success", `已添加`, "");
      }

      localStorage.setItem(FILIAL_LOCAL_STORAGE_KEY, JSON.stringify(newAll));
    } catch (error: any) {
      openNotificationWithIcon("error", error.response.msg, "");
      setAddloaing(false);
    }

    // OrderService.scanAndUpdateOrder({
    //   barCode: values.barCode,
    //   orderGroup: openEdit.orderGroupId,
    // })
    //   .then((res: any) => {
    //     setAddloaing(false);
    //     if (!!res.data) {
    //       openNotificationWithIcon("success", `已添加${res.data.barCode}`, "");

    //       setOrders({
    //         orderData: [res.data, ...orders.orderData],
    //         total: orders.total + 1,
    //       });
    //     } else {
    //       openNotificationWithIcon("error", res.msg, "");
    //     }
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //     setAddloaing(false);

    //     if (err.response.status === 422) {
    //       openNotificationWithIcon("error", `条码已存在`, "");
    //       return;
    //     }
    //     openNotificationWithIcon("error", err.response.msg, "");
    //   });
  };

  const handleCorrectOrder = async (barCode: string, index: number) => {
    try {
      setFreshing(true);
      const res = await OrderService.clientPreCheck({ barCode: barCode });

      setScanCodes((current) => {
        current[index] = res.data;
        return current;
      });
      if (res.data.status !== OrderStatus.ARRIVED_AT_KAZAKHSTAN) {
        audioRef.current.play();
        setFreshing(false);
        return;
      }

      const fillial_existing_orders = fecthExistingOrderinLocalstorage();
      let newAll: {
        orderGroupId: string;
        barCode: IORDERS[];
      }[] = [];

      if (
        fillial_existing_orders &&
        fillial_existing_orders.all.length > 0 &&
        fillial_existing_orders.index > -1
      ) {
        newAll = fillial_existing_orders.all;
        let currBarcode = fillial_existing_orders.currentOrderGroup.barCode;
        const corretBarcodeIndex = currBarcode.findIndex(
          (elem) => elem.barCode === barCode
        );
        currBarcode[corretBarcodeIndex] = res.data;
        newAll[fillial_existing_orders.index] = {
          orderGroupId: openEdit.orderGroupId,
          barCode: currBarcode,
        };
      }

      localStorage.setItem(FILIAL_LOCAL_STORAGE_KEY, JSON.stringify(newAll));
      setFreshing(false);
    } catch (error) {
      console.error(error);
      setFreshing(false);
    }
  };

  return (
    <Card
      className={scanBarcodeMode ? "scanMode" : ""}
      style={{ marginBottom: 10 }}
      //   bodyStyle={{ background: scanBarcodeMode ? "#e8e8e8" : "#fff" }}
    >
      <Row justify="space-between">
        {orderGroup.status !== OrderStatus.PENDING ? (
          <Tag color="red">Уже отправлено</Tag>
        ) : (
          <Button
            style={{ marginBottom: 20 }}
            type={scanBarcodeMode ? "default" : "primary"}
            onClick={() => setScanBarCodeMode((current) => !current)}
          >
            {scanBarcodeMode
              ? "Штрих-код режимінен бас тарту"
              : "штрих-код қосу"}
          </Button>
        )}

        {/* {scanBarcodeMode ? (
          <Form
            name="basic"
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            onFinish={(values: any) => onFinish(values)}
            layout="inline"
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label=""
              name="barCode"
              // rules={[{ required: true, message: "Пожалуйста, введите длину!!" }]}
            >
              <Input placeholder="Штрих-код" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Қосу
              </Button>
            </Form.Item>
          </Form>
        ) : null} */}
      </Row>
      {scanBarcodeMode === true ? (
        <>
          <BarcodeReader
            onError={(err: any) => {
              console.log(err);
            }}
            onScan={(data: string) => onCheckOrder(data)}
          />
          {/* <BarcodeReader
            onError={(err: any) => {
              console.log(err);
            }}
            onScan={(data: string) => {
              setAddloaing(true);
              OrderService.scanAndUpdateOrder({
                barCode: data,
                orderGroup: openEdit.orderGroupId,
              })
                .then((res: any) => {
                  setAddloaing(false);
                  if (res.data.barCode) {
                    openNotificationWithIcon(
                      "success",
                      `Сәтті【${res.data.barCode}】`,
                      ""
                    );
                  } else {
                    openNotificationWithIcon(
                      "success",
                      `Қате ${res.data.barCode}】`,
                      ""
                    );
                  }

                  setOrders({
                    orderData: [res.data, ...orders.orderData],
                    total: orders.total + 1,
                  });
                })
                .catch((err) => {
                  console.log(err);
                  setAddloaing(false);

                  if (err.response.status === 422) {
                    openNotificationWithIcon(
                      "error",
                      `Штрих-код уже существует`,
                      ""
                    );
                  }
                  // openNotificationWithIcon(
                  //   "error",
                  //   `${res.barCode} 条码已存在`,
                  //   ""
                  // );
                });

              // setScanCodes((current) => [...current, data]);
            }} */}
          <Card>
            <audio src="/alert.wav" preload="auto" ref={audioRef}></audio>
            <Form
              style={{ marginBottom: 16 }}
              name="basic"
              onFinish={(val: any) => onCheckOrder(val.barCode)}
              // onFinish={(values: any) => onFinish(values)}
              layout="inline"
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label=""
                name="barCode"

                // rules={[{ required: true, message: "Пожалуйста, введите длину!!" }]}
              >
                <Input placeholder="条码/штрих-код" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  添加/Добавить
                </Button>
              </Form.Item>
            </Form>
            <div>
              <b>Штрих-код</b> (После заполнения нажмите "Подтвердить" для
              получения товара)
            </div>
            <List
              id="listRef"
              style={{ height: 250, overflow: "auto" }}
              loading={loadingOrder}
              // header={
              //   <div>
              //     <b>Штрих-код</b> (После заполнения нажмите "Подтвердить" для
              //     получения товара)
              //   </div>
              // }
              // footer={
              //   <div>
              //     <Button
              //       type="primary"
              //       loading={loading}
              //       disabled={false}
              //       //onClick={() => handleConfirmRecivedOrder()}
              //       // onKeyDown={(event) => {
              //       //   if (event.key === "Enter") {
              //       //     console.log("enter press here! ");
              //       //   }
              //       // }}
              //     >
              //       Подтвердить
              //     </Button>
              //   </div>
              // }
              //filial todo
              bordered
              dataSource={scanedCodes}
              renderItem={(item, index) => (
                <List.Item
                  actions={[
                    item.status !== OrderStatus.ARRIVED_AT_KAZAKHSTAN ? (
                      <>
                        <Button
                          key="list-loadmore-edit"
                          type="link"
                          onClick={() => {
                            // setOpenEdit({
                            //   open: true,
                            //   orderGroupId: item.orderGroup,
                            // });
                            window.open(
                              "/admin/orders",
                              "_blank",
                              "rel=noopener noreferrer"
                            );
                            //handleCancelBarcode(index);
                          }}
                        >
                          单号状态不符合，请修改 / Неверный статус, исправьте
                        </Button>
                        <Space />
                        <Button
                          onClick={() =>
                            handleCorrectOrder(item.barCode, index)
                          }
                          loading={refreshing}
                        >
                          {refreshing ? null : <SyncOutlined />}
                        </Button>
                      </>
                    ) : null,
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => handleCancelBarcode(index)}
                      danger
                    >
                      <DeleteOutlined />
                    </Button>,
                  ]}
                >
                  <div>
                    {" "}
                    <Typography.Text mark>
                      {scanedCodes.length - index}.
                    </Typography.Text>{" "}
                    {item.barCode}
                  </div>
                </List.Item>
              )}
            />
            <Button
              type="primary"
              loading={loading}
              disabled={false}
              onClick={handleSubmitOrderBarcode}
              // onKeyDown={(event) => {
              //   if (event.key === "Enter") {
              //     console.log("enter press here! ");
              //   }
              // }}
            >
              Подтвердить
            </Button>
          </Card>
        </>
      ) : (
        <Table
          loading={orderLoading}
          rowKey="_id"
          columns={columns}
          dataSource={orders.orderData}
          pagination={{
            pageSize: 5,
            total: orders.total,
            onChange: orderPageChangeHandle,
          }}
        />
      )}
      {/* {scanBarcodeMode === true ? (
        
      ) : null} */}
      {/* <Table
      loading={orderLoading}
      columns={columns}
      dataSource={orders.orderData}
      // expandable={{
      //   expandedRowRender,
      //   expandedRowKeys: orderExpandedRowKeys,
      //   onExpand: onExpandedRowsChange,
      // }}
      rowKey="_id"
      pagination={{
        pageSize: 12,
        total: orders.total,
        onChange: orderGroupPageChangeHandle,
      }}
    /> */}

      <Modal
        centered
        width={"min-content"}
        closable={false}
        title=""
        open={addLoading}
        footer={null}
        onCancel={() => setAddloaing(false)}
      >
        <Spin />
      </Modal>
    </Card>
  );
};

export default UpdateOrderBarCode;
