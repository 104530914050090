import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Statistic,
  Table,
  Tag,
} from "antd";
import React, { useState } from "react";
import FinanceService from "../../../../service/FinanceService";
import locale from "antd/es/date-picker/locale/ru_RU";
import { getOrderStatusEnumText } from "../../../../utils/getEnumText";
import { formatDate } from "../../../../utils/notificationUtil";

const Finance = () => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const [reports, setReports] = useState<any>([]);

  const columns = [
    // {
    //   title: "品名",
    //   dataIndex: "key",
    //   key: "key",
    // },
    {
      title: "收件人/Получатель",
      dataIndex: "iin",
      key: "iin",
    },

    {
      title: "汇率/Валюта",
      key: "currency",
      dataIndex: "currency",
      // render: (text: string, record: any) => {
      //   return <p>{record?.orderGroup?.title}</p>;
      // },
    },

    {
      title: "重量/Вес",
      key: "weight",
      dataIndex: "weight",
      // render: (text: string, record: any) => {
      //   return <p>{record?.orderGroup?.title}</p>;
      // },
    },

    {
      title: "价格(阿拉木图)$/Цена за килограмм (Алматы)$",
      key: "price",
      dataIndex: "price",
      // render: (text: string, record: any) => {
      //   const data = getOrderStatusEnumText(record.status);
      //   if (data) {
      //     return <Tag color={data.color}>{data.text}</Tag>;
      //   }
      // },
    },

    {
      title: "收费(坚戈)/Платеж(тенге)",
      key: "fee",
      dataIndex: "fee",
      // render: (text: string, record: any) => {
      //   const data = getOrderStatusEnumText(record.status);
      //   if (data) {
      //     return <Tag color={data.color}>{data.text}</Tag>;
      //   }
      // },
    },

    {
      title: "日期/Дата",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (text: string, record: any) => {
        return <p>{formatDate(record.createdAt)}</p>;
      },
    },

    // {
    //   title: "更多",
    //   key: "action",
    //   render: (text: string, record: any) => (
    //     <>
    //       <Space size="middle">
    //         <Button
    //           danger
    //           type="primary"
    //           onClick={() => {
    //             deleteCityConfirm(record._id);
    //           }}
    //         >
    //           删除
    //         </Button>
    //       </Space>
    //       <Space size="middle">
    //         <Button
    //           type="primary"
    //           onClick={() => {
    //             showDrawerEdit(record);
    //           }}
    //         >
    //           修改
    //         </Button>
    //       </Space>
    //     </>
    //   ),
    // },
  ];

  const pageChangeHandle = async (page: number, pageSize: number) => {
    setLoading(true);

    const status = form.getFieldValue("status");
    const startDate = form.getFieldValue("startDate");
    const endDate = form.getFieldValue("endDate");
    const res = await FinanceService.reportFinance(page, 12, {
      startDate,
      endDate,
    });
    setReports(res.data);
    setLoading(false);
  };

  const onFinish = async (value: any) => {
    // console.log(value);

    const response = await FinanceService.reportFinance(1, 12, value);
    // console.log(response);
    setReports(response.data);
  };
  return (
    <>
      <Card>
        {" "}
        <Form
          form={form}
          layout="inline"
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            style={{ marginTop: 16 }}
            label="日期从/Дата от"
            name="startDate"
            required
          >
            <DatePicker locale={locale} />
            {/* </ConfigProvider> */}
          </Form.Item>

          <Form.Item
            style={{ marginTop: 16 }}
            label="日期至/Дата до"
            name="endDate"
            required
          >
            <DatePicker locale={locale} />
          </Form.Item>

          <Form.Item
            style={{ marginTop: 16 }}
            // wrapperCol={{ offset: 8, span: 16 }}
          >
            <Button type="primary" htmlType="submit">
              OK
            </Button>
          </Form.Item>
        </Form>
      </Card>

      <Card>
        <Row gutter={16}>
          <Col span={12}>
            <Statistic
              title="订单数量/Количество заказов"
              value={reports?.total}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="总收入（坚戈）/ Общий доход (тенге)"
              value={reports?.totalFee}
              // precision={2}
            />
          </Col>
          <Col span={12} style={{ marginTop: 16, marginBottom: 16 }}>
            <Statistic
              title="总公斤（坚戈）/ Общий кг"
              value={reports?.totalWeight}
              // precision={2}
            />
          </Col>
        </Row>

        <Table
          loading={loading}
          // rowKey={"barCode"}
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={reports?.result}
          expandable={{
            expandedRowRender: (record) =>
              record.barCodes.map((item: any, index: number) => (
                <p style={{ margin: 0 }}>
                  {" "}
                  <span>{index + 1}. </span> <b>{item}</b>
                </p>
              )),
            // rowExpandable: (record) => record.name !== "Not Expandable",
          }}
          scroll={{ y: 460 }}
          pagination={{
            pageSize: 12,
            total: reports?.total,
            current: reports?.currentPage,
            onChange: pageChangeHandle,
          }}
        />
      </Card>
    </>
  );
};

export default Finance;
