import { Button, Card, Modal, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductOrderService from "../../../../service/ProductOrderService";
// import AddProductOrder from "./AddProductOrder";
// import EditProductOrder from "./EditProductOrder";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import AddProductOrder from "./AddProductOrder";
import EditProductOrder from "./EditProductOrder";
import Search from "antd/lib/input/Search";

const { confirm } = Modal;

const ProductOrder = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const [openEdit, setOpenEdit] = useState({
    open: false,
    ProductOrderId: "",
  });
  const [ProductOrder, setProductOrder] = useState<any>([]);

  const showDrawerAdd = () => {
    setOpenAdd(true);
  };

  const showDrawerEdit = (record: any) => {
    setOpenEdit({ open: true, ProductOrderId: record._id });
  };
  const fetchOrders = async () => {
    setLoading(true);
    const result = await ProductOrderService.fetchFilter(1, 12, search);

    setProductOrder({
      data: result.data.productOrders,
      total: result.data.total,
    });
    setLoading(false);
  };
  useEffect(() => {
    fetchOrders();
  }, []);

  const columns = [
    // {
    //   title: "品名",
    //   dataIndex: "key",
    //   key: "key",
    // },
    {
      title: "品名",
      dataIndex: "product.title_ru",
      key: "product",
      render: (text: string, record: any) => record?.product?.title_ru,
    },
    {
      title: "客户名称",
      dataIndex: "customer.name",
      key: "customer.name",
      render: (text: string, record: any) => record?.customer?.name,
    },
    {
      title: "客户电话",
      dataIndex: "customer.telephone",
      key: "customer.telephone",
      render: (text: string, record: any) => record?.customer?.telephone,
    },

    // {
    //   title: "更多",
    //   key: "action",

    //   render: (text: string, record: any) => (
    //     <>
    //       <Space size="middle">
    //         <Button
    //           danger
    //           type="primary"
    //           onClick={() => {
    //             deleteProductOrderConfirm(record._id);
    //           }}
    //         >
    //           删除
    //         </Button>
    //       </Space>
    //       <Space size="middle">
    //         <Button
    //           type="primary"
    //           onClick={() => {
    //             showDrawerEdit(record);
    //           }}
    //         >
    //           修改
    //         </Button>
    //       </Space>
    //     </>
    //   ),
    // },
  ];

  const pageChangeHandle = async (page: number, pageSize: number) => {
    setLoading(true);
    const res = await ProductOrderService.fetchFilter(page, 12, search);
    setProductOrder({
      data: res.data.productOrders,
      total: res.data.total,
    });
    setLoading(false);
  };

  const deleteProductOrder = async (id: string) => {
    await ProductOrderService.delete(id);
    window.location.reload();
  };

  const deleteProductOrderConfirm = (id: string) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "确认删除？",
      okText: "确认",
      cancelText: "取消",
      onOk() {
        deleteProductOrder(id);
      },
    });
  };

  const handleOnSearch = async (val: string) => {
    setSearch(val);
    fetchOrders();
    setLoading(false);
  };

  return (
    <Card>
      <Search
        onChange={(e) => setSearch(e.target.value)}
        style={{ width: "30%" }}
        placeholder="搜索条码组"
        onSearch={(val) => handleOnSearch(val)}
      />
      {/* <Button
        onClick={showDrawerAdd}
        style={{
          marginTop: 20,
          marginBottom: 20,
          marginLeft: 20,
          backgroundColor: "green",
        }}
        type="primary"
      >
        添加
      </Button> */}
      <Table
        loading={loading}
        dataSource={ProductOrder.data}
        columns={columns}
        rowKey="_id"
        pagination={{
          pageSize: 12,
          total: ProductOrder.total,
          onChange: pageChangeHandle,
        }}
      />

      {openEdit.open && (
        <EditProductOrder openData={openEdit} setOpenData={setOpenEdit} />
      )}
    </Card>
  );
};

export default ProductOrder;
