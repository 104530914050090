import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useRef, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import { Button, Input, Modal, Row, Space, Table } from "antd";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { ICabinet } from "../ICabinet";
import AddNewBarCode from "./add-new-barcode";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectId } from "../../../../redux/slices/customerSlice";

import CabinetService from "../../../../service/CabinetService";
import BarCodeDetail from "./barcode-detail";
import { formatDate } from "../../../../utils/notificationUtil";
import { PaginationProps } from "antd/es/pagination";

export interface IBarCodesList {
  data: any[];
  total: number;
}

export interface IBarCodeDetail {
  open: boolean;
  barCodeId: string;
}

function CabinetDataTable() {
  const customerId = useSelector(selectId);
  let params = useParams();
  // const [addModalOpen, setAddModalOpen] = useState(false);
  const [barCodesList, setBarCodesList] = useState<IBarCodesList>({
    data: [],
    total: 0,
  });
  const [loading, setLoading] = useState(true);

  const [barCodeDetailState, setBarCodeDetailState] = useState<IBarCodeDetail>({
    open: false,
    barCodeId: "",
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const { confirm } = Modal;

  // const updateQuery = (e) => setQuery(e?.target?.value);

  const sendQuery = (q: string) => {
    if (customerId === "") {
      return;
    }
    const type = params.barCodeType || "null";
    const status = params.status || "null";
    const paginateBarCodes = async (type: string, status: string) => {
      const result = await CabinetService.paginate(type, status, 1, 12, q);
      setBarCodesList({
        data: result.data.customerBarCodes,
        total: result.data.total,
      });
      setLoading(false);
    };
    paginateBarCodes(type, status);
    setCurrentPage(1);
  };

  useEffect(() => {
    sendQuery("");
    // setSearchQuery("");
  }, [params.barCodeType, customerId]);

  const delayedQuery = useCallback(
    debounce((q) => sendQuery(q), 700),
    []
  );

  const onChangeSearch = (e: any) => {
    setSearchQuery(e.target.value);
    delayedQuery(e.target.value);
  };

  const pageChangeHandle: PaginationProps["onChange"] = async (
    page: number,
    pageSize: number
  ) => {
    setCurrentPage(page);
    setLoading(true);
    const res = await CabinetService.paginate(
      params.barCodeType || "",
      params.status || "",
      page,
      12,
      searchQuery
    );
    setBarCodesList({
      data: res.data.customerBarCodes,
      total: res.data.total,
    });
    setLoading(false);
  };

  const openBarcodeDetail = (id: string) => {
    setBarCodeDetailState({ barCodeId: id, open: true });
  };

  // const deleteBarcode = async (id: string) => {
  //   const response = await CabinetService.delete(id);

  //   if (!!response.data._id) {
  //     window.location.reload();
  //   }
  // };

  // const deleteBarcodeConfirm = (id: string) => {
  //   confirm({
  //     icon: <ExclamationCircleOutlined />,
  //     title: "Удаление записи",
  //     content: <p>Вы уверены, что хотите удалить эту запись?</p>,
  //     okText: "Подтвердить",
  //     cancelText: "Отмена",
  //     onOk() {
  //       deleteBarcode(id);
  //     },
  //   });
  // };

  const columns: ColumnsType<ICabinet> = [
    {
      title: "Трек-кода",
      dataIndex: "barCode",
    },
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      render: (text) => <b>{formatDate(text)}</b>,
    },
    {
      title: "Действие",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            size={"small"}
            onClick={() => openBarcodeDetail(record._id)}
          >
            <EyeOutlined />
          </Button>

          {/* <Button
            size={"small"}
            type="primary"
            onClick={() => deleteBarcodeConfirm(record._id)}
            danger
          >
            <DeleteOutlined />
          </Button> */}
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        loading={loading}
        rowKey={"barCode"}
        columns={columns}
        dataSource={barCodesList.data}
        scroll={{ y: 460 }}
        pagination={{
          pageSize: 12,
          total: barCodesList.total,
          current: currentPage,
          onChange: pageChangeHandle,
        }}
      />
      {/* <AddNewBarCode
        openData={addModalOpen}
        setOpenData={setAddModalOpen}
        setBarCodesList={setBarCodesList}
        type={params.barCodeType}
      /> */}

      {barCodeDetailState.open && (
        <BarCodeDetail
          openDetail={barCodeDetailState}
          setOpenDetail={setBarCodeDetailState}
        />
      )}
    </>
  );
}

export default CabinetDataTable;
