import { Drawer, Space, Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { BarCodeType } from "../../../../../configs/CabinetNavigation";
import CabinetService from "../../../../../service/CabinetService";
import { getOrderStatusEnumTextRus } from "../../../../../utils/getEnumText";
import {
  CustomerBarCodeStatusColor,
  OrderStatusColor,
} from "../../../home/statusOrder";
import { TimelineInterface } from "../../../order-details/OrderDetails";
import TimelineOrder from "../../../order-details/TimelineOrder";
import { ICabinet } from "../../ICabinet";
import { IBarCodeDetail } from "../CabinetDataTable";

interface IProps {
  openDetail: IBarCodeDetail;
  setOpenDetail: any;
}

interface ITagBarCodeStatus {
  color: CustomerBarCodeStatusColor | OrderStatusColor | string;
  text: "";
}

interface IBarCodeDetailState {
  customerBarCode: ICabinet;
  barCodeStatus: string;
  timeline: TimelineInterface[];
  tag: ITagBarCodeStatus;
}

function BarCodeDetail({ openDetail, setOpenDetail }: IProps) {
  const [barCodeDetail, setBarCodeDetail] = useState<IBarCodeDetailState>({
    customerBarCode: {
      barCode: "",
      status: "",
      createdAt: "",
      customer: "",
      _id: "",
      id: "",
      type: BarCodeType.PACKAGE,
      updatedAt: "",
    },
    barCodeStatus: "",
    tag: {
      color: "",
      text: "",
    },
    timeline: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchBarCodeDetail() {
      setLoading(true);
      const res = await CabinetService.findOne(openDetail.barCodeId);
      setBarCodeDetail(res.data);
      setLoading(false);
    }
    fetchBarCodeDetail();
  }, []);

  const onClose = () => {
    setOpenDetail((currVal: IBarCodeDetail) => ({ ...currVal, open: false }));
  };
  // const data = getOrderStatusEnumText(record.status);
  //         if (data) {
  //           return <Tag color={data.color}>{data.text}</Tag>;
  //         }
  return (
    <Drawer
      title={
        <Space>
          {barCodeDetail.customerBarCode.barCode}
          <Tag
            color={
              getOrderStatusEnumTextRus(barCodeDetail.barCodeStatus)?.color
            }
          >
            {getOrderStatusEnumTextRus(barCodeDetail.barCodeStatus)?.text}
          </Tag>
        </Space>
      }
      placement="right"
      onClose={onClose}
      width={"auto"}
      open={openDetail.open}
    >
      <Spin spinning={loading} size="large">
        <div className="order_details">
          <TimelineOrder timeline={barCodeDetail.timeline} message_ru={true} />
        </div>
      </Spin>
    </Drawer>
  );
}

export default BarCodeDetail;
