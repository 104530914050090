import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  InputNumber,
  notification,
  Pagination,
  Select,
  Space,
  Spin,
} from "antd";
import { NotificationPlacement } from "antd/lib/notification";
import React, { useEffect, useRef, useState } from "react";
import CategoryService from "../../../../service/CategoryService";
import TextArea from "antd/lib/input/TextArea";
import PhotoService from "../../../../service/PhotoService";
import {
  API_IMG_NO_OWNER_URL,
  API_IMG_PRODUCT,
  API_IMG_URL,
} from "../../../../configs/AppConfig";

function EditCategory({ openData, setOpenData }: any) {
  const formRef = useRef<any>();
  const [loading, setLoading] = useState(true);
  const [photo, setPhoto] = useState<any>([]);
  const [selectLoading, setSelectLoading] = useState(false);
  useEffect(() => {
    const fetchCategory = async () => {
      const response = await CategoryService.getOne(openData.CategoryId);

      formRef.current.setFieldsValue({
        _id: response.data._id,
        title_ru: response.data.title_ru,
        title_kk: response.data.title_kk,
        title_zh: response.data.title_zh,
      });
      setLoading(false);
    };
    fetchCategory();
  }, []);

  const openNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: `Success`,
      description: "更改成功",
      placement,
    });
  };
  const onClose = () => {
    setOpenData({ open: false, CategoryId: "" });
  };
  const onFinish = async (values: any) => {
    setLoading(true);

    // _id: response.data._id,
    //     title_ru: response.data.title_ru,
    //     title_kk: response.data.title_kk,
    //     isMain: response.data.isMain,

    await CategoryService.update(values, openData.CategoryId);
    openNotification("topRight");
    setLoading(false);
    window.location.reload();
  };

  return (
    <Drawer
      title="修改类别"
      placement="right"
      onClose={onClose}
      open={openData.open}
      width={"50%"}
    >
      <Spin size="large" spinning={loading}>
        <Form
          ref={formRef}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="名称RU" name="title_ru" required>
            <Input />
          </Form.Item>
          <Form.Item label="名称KZ" name="title_kk" required>
            <Input />
          </Form.Item>
          <Form.Item label="名称CN" name="title_zh" required>
            <Input />
          </Form.Item>

          <Form.Item
            wrapperCol={{ offset: 8, span: 16 }}

            // wrapperCol={{ offset: 8, span: 16 }}
          >
            <Button type="primary" htmlType="submit">
              修改
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default EditCategory;
