import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input, Modal } from "antd";
import Cargo from "../../../img/cargo.jpg";
import { Link, useNavigate } from "react-router-dom";
import "../../app-views/phoneConfirm/style.css";

import ConfirmModal from "../../../components/confirmModal";
import MaskedInput from "antd-mask-input/build/main/lib/MaskedInput";
import CustomerService from "../../../service/CustomerService";
import { openNotificationWithIcon } from "../../../utils/notificationUtil";

const ForgetPassword = () => {
  const [form] = Form.useForm();
  const [formPassword] = Form.useForm();
  const [formCode] = Form.useForm();
  const [loading, setLoading] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [isMatch, setIsMatch] = useState(false);

  let history = useNavigate();

  const [smsCodeState, setSmsCodeState] = useState({
    loading: false,
    limit_time: new Date(),
    sent: false,
    error_msg: "",
    tel: "",
  });

  const confirmPhoneHanler = async (values: any) => {
    const response = await CustomerService.forgetPass(
      form.getFieldValue("telephone"),
      values.user_input
    );
    setIsMatch(response.data.match);
    if (response.data.match === false) {
      openNotificationWithIcon("error", "Неверный код подтверждения", "");
      return;
    }

    formPassword.setFieldValue("user_input", values.user_input);
    formPassword.setFieldValue("telephone", form.getFieldValue("telephone"));

    // dispatch(verify({ user_input: values.user_input, telephone }));
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds((currSecond) => currSecond - 1);
      }
      if (seconds === 0) {
        clearInterval(myInterval);
        setSmsCodeState({
          ...smsCodeState,
          sent: false,
          limit_time: new Date(),
        });
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const smsCodeReciveHandler = async (telephone: any) => {
    setSmsCodeState({ ...smsCodeState, loading: true });
    const response = await CustomerService.sendCodeforgetPass(telephone);

    if (!!response.data.error_msg) {
      openNotificationWithIcon("warning", response.data.error_msg, "");
    }

    if ((response.data.message = "Sent")) {
      setSmsCodeState({
        ...smsCodeState,
        loading: false,
        sent: true,
        limit_time: response.data.next_sms_limit_time,
      });

      const left_seconds =
        new Date(response.data.next_sms_limit_time).getTime() -
        new Date().getTime();

      setSeconds(Math.floor(left_seconds / 1000));

      setIsModalOpen(true);
    }
  };

  const changePasswordHandler = async (values: any) => {
    const response = await CustomerService.changePassword(values);
    if (response.data.msg === "OK") {
      openNotificationWithIcon("success", "Обновлено", "");
      history("/checkin");
    } else {
      openNotificationWithIcon("error", "Ошибка", "");
    }
  };

  const onFinish = async (values: any) => {
    setSmsCodeState({ ...smsCodeState, tel: values.telephone });
    smsCodeReciveHandler(values.telephone);
  };
  return (
    <div className="phoneConfirm">
      <Card>
        <h3>Для восстановления аккаунта введите свой номер телефона.</h3>
        <img src={Cargo} alt="Cargo" width={250} />
        <Form
          form={form}
          layout="vertical"
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          title=""
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Номер телефон"
            name="telephone"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите свой номер телефона!",
              },
            ]}
          >
            <MaskedInput
              mask={
                //  https://imask.js.org/guide.html#masked-pattern
                "+7(000)000-00-00"
              }
            />
          </Form.Item>

          <Form.Item>
            <Button type="link" htmlType="button">
              <Link to={"/register"}>Отмена</Link>
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Продолжать
              {/* Восстанавливать */}
            </Button>
          </Form.Item>
        </Form>
      </Card>

      <Modal
        className="phoneConfirmModal"
        footer={[
          <Button
            form="changePassword"
            key="submit"
            type="primary"
            htmlType="submit"
            onClick={changePasswordHandler}
            loading={loading}
          >
            Подтвердить
          </Button>,
        ]}
        width={300}
        closable={false}
        title="Подтверждение номера"
        open={isMatch}
      >
        <Form
          id="changePassword"
          form={formPassword}
          layout="vertical"
          name="confirm"
          // labelCol={{ span: 24 }}
          // wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={changePasswordHandler}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item hidden name="telephone">
            <Input className="login_input" />
          </Form.Item>
          <Form.Item hidden name="user_input">
            <Input className="login_input" />
          </Form.Item>
          <Form.Item
            label={"Пароль"}
            name="password"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите пароль!",

                min: 6,
              },
            ]}
          >
            <Input.Password className="login_input" />
          </Form.Item>
          <Form.Item
            label={"Подтверждение пароля"}
            name="passwordConfirm"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Пожалуйста, подтвердите свой пароль!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Пароли не совпадают!"));
                },
              }),
            ]}
          >
            <Input.Password className="login_input" />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        className="phoneConfirmModal"
        footer={[
          <Button
            form="confirm_phone_btn"
            key="submit"
            type="primary"
            htmlType="submit"
            // onClick={confirmPhoneHanler}
            loading={loading}
          >
            Подтвердить
          </Button>,
        ]}
        width={300}
        closable={false}
        title="Подтверждение номера"
        open={isModalOpen}
      >
        <Form
          id="confirm_phone_btn"
          form={formCode}
          layout="vertical"
          name="confirm"
          // labelCol={{ span: 24 }}
          // wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={confirmPhoneHanler}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Код подтверждения"
            name="user_input"
            rules={[
              {
                required: true,
                min: 5,
                max: 5,
                message: "Введите код подтверждения!",
              },
            ]}
          >
            <div className="flex-center">
              <Input.Group compact style={{ width: "100%", display: "flex" }}>
                <Input
                  maxLength={5}
                  minLength={5}
                  style={{ flex: 1 }}
                  //style={{ width: "calc(100% - 150px)" }}
                  defaultValue=""
                />
                <Button
                  type="primary"
                  ghost
                  loading={smsCodeState.loading}
                  disabled={smsCodeState.sent}
                  onClick={() =>
                    smsCodeReciveHandler(form.getFieldValue("telephone"))
                  }
                >
                  {smsCodeState.sent ? `${seconds} сек` : "Отправить код"}
                </Button>
              </Input.Group>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ForgetPassword;
