import JwtAuthService from "../../../service/JwtAuthService";
// import UserService from "../../../service/UserService";

export function signOutRequest() {
  return JwtAuthService.signOut();
}

export function signInRequest(data: any) {
  return JwtAuthService.login(data);
}

export function signUpRequest(data: any) {
  return JwtAuthService.signUp(data);
}

// export function updateContactRequest(data: any) {
//   return UserService.updateContactInfo(data);
// }
