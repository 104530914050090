import axios from "axios";

import history from "../history";
import { REGISTER_TEL } from "../redux/constants/Auth";
import { notification } from "antd";
import { API_BASE_URL } from "../configs/AppConfig";

// const auth = JSON.parse(sessionStorage.getItem(REGISTER_TEL) || "{}");
const service = axios.create({
  baseURL: API_BASE_URL,
  // + "/customer-barcodo?telephone=" + auth,
  timeout: 60000,
});

// Config
const ENTRY_ROUTE = "/checkin";
const TOKEN_PAYLOAD_KEY = "authorization";
const PUBLIC_REQUEST_KEY = "public-request";
// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const auth = JSON.parse(localStorage.getItem(REGISTER_TEL) || "{}");
    const jwtToken = auth?.accessToken;

    if (!config.headers) {
      return;
    }
    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = "Bearer " + jwtToken;
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log(error.response);

    let notificationParam = {
      message: "Ошибка",
      description: "",
    };

    if (error.response === undefined) {
      notificationParam.message = "Нет подключения";
      notificationParam.description = "пожалуйста, проверьте";
    }

    // Remove token and redirect
    if (error.response?.status === 400) {
      notificationParam.message = "Неверный запрос";
      notificationParam.description = "Пожалуйста, попробуйте еще раз";
    }
    if (error.response?.status === 403 || error.response?.status === 401) {
      notificationParam.message = "Ошибка аутентификации";
      notificationParam.description = "Пожалуйста, войдите снова";
      localStorage.removeItem(REGISTER_TEL);
      history.push(ENTRY_ROUTE);
      //setTimeout(() => window.location.reload(), 1000);
    }

    if (error.response?.status === 404) {
      notificationParam.message = "Не существует";
    }

    if (error.response?.status === 500) {
      notificationParam.message = "Внутренняя ошибка сервера";
    }

    if (error.response?.status === 508) {
      notificationParam.message = "короткий перерыв в работе";
    }

    if (error.response?.status === 422) {
      notificationParam.message =
        error.response.data.message || "Уже существует в системе";
      // setTimeout(function () {
      //   sessionStorage.removeItem(REGISTER_TEL);
      //   history.push(ENTRY_ROUTE);
      //   window.location.reload();
      // }, 500);
    }

    if (!!error.response.data.message) {
      notificationParam.message = error.response.data.message;
      notificationParam.description = "";
    }

    notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service;
