import {
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
} from "antd";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsModalShown,
  selectLoading,
  selectTelephone,
  verify,
} from "../../redux/slices/customerSlice";
import CustomerService from "../../service/CustomerService";
import { openNotificationWithIcon } from "../../utils/notificationUtil";

function ConfirmModal() {
  const loading = useSelector(selectLoading);
  const telephone = useSelector(selectTelephone);
  const isModalOpen = useSelector(selectIsModalShown);
  const [smsCodeState, setSmsCodeState] = useState({
    loading: false,
    limit_time: new Date(),
    sent: false,
    error_msg: "",
  });
  const [seconds, setSeconds] = useState(0);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const smsCodeReciveHandler = async () => {
    setSmsCodeState({ ...smsCodeState, loading: true });
    const response = await CustomerService.sendCode(telephone);

    if (!!response.data.error_msg) {
      openNotificationWithIcon("warning", response.data.error_msg, "");
    }

    if ((response.data.message = "Sent")) {
      setSmsCodeState({
        ...smsCodeState,
        loading: false,
        sent: true,
        limit_time: response.data.next_sms_limit_time,
      });

      const left_seconds =
        new Date(response.data.next_sms_limit_time).getTime() -
        new Date().getTime();

      setSeconds(Math.floor(left_seconds / 1000));
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      smsCodeReciveHandler();
    }
  }, [isModalOpen]);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds((currSecond) => currSecond - 1);
      }
      if (seconds === 0) {
        clearInterval(myInterval);
        setSmsCodeState({
          ...smsCodeState,
          sent: false,
          limit_time: new Date(),
        });
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const confirmPhoneHanler = (values: any) => {
    dispatch(verify({ user_input: values.user_input, telephone }));
  };

  // useEffect(() => {
  //   smsCodeState
  // }, []);

  // useEffect(() => {
  //   if (verfied === false) {
  //     setIsModalOpen(true);
  //   }
  // }, [verfied]);

  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };
  // const showModal = () => {
  //   setIsModalOpen(true);
  // };

  return (
    <Modal
      className="phoneConfirmModal"
      footer={[
        <Button
          form="confirm_phone_btn"
          key="submit"
          type="primary"
          htmlType="submit"
          // onClick={confirmPhoneHanler}
          loading={loading}
        >
          Подтвердить
        </Button>,
      ]}
      width={300}
      closable={false}
      title="Подтверждение номера"
      open={isModalOpen}
    >
      <Form
        id="confirm_phone_btn"
        form={form}
        layout="vertical"
        name="confirm"
        // labelCol={{ span: 24 }}
        // wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={confirmPhoneHanler}
        //   onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Код подтверждения"
          name="user_input"
          rules={[
            {
              required: true,
              min: 5,
              max: 5,
              message: "Введите код подтверждения!",
            },
          ]}
        >
          <div className="flex-center">
            <Input.Group compact style={{ width: "100%", display: "flex" }}>
              <Input
                maxLength={5}
                minLength={5}
                style={{ flex: 1 }}
                //style={{ width: "calc(100% - 150px)" }}
                defaultValue=""
              />
              <Button
                type="primary"
                ghost
                loading={smsCodeState.loading}
                disabled={smsCodeState.sent}
                onClick={smsCodeReciveHandler}
              >
                {smsCodeState.sent ? `${seconds} сек` : "Отправить код"}
              </Button>
            </Input.Group>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ConfirmModal;
