import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  message,
  notification,
  Pagination,
  Select,
  Space,
  Spin,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { NotificationPlacement } from "antd/lib/notification";
import React, { useEffect, useState } from "react";
import NoCodeOwnerService from "../../../../service/NoCodeOwnerService";
import TextArea from "antd/lib/input/TextArea";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";
import PhotoBarcodeService from "../../../../service/PhotoBarcodeService";
import { API_IMG_NO_OWNER_URL } from "../../../../configs/AppConfig";

function AddNoCodeOwner({ openData, setOpenData }: any) {
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState<any>([]);
  const [selectLoading, setSelectLoading] = useState(false);

  const openNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: `Success`,
      description: "添加成功",
      placement,
    });
  };

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      const result = await PhotoBarcodeService.fetchFilter(1);

      setPhoto({
        data: result.data.noOwnerBarCodePhotos,
        total: result.data.total,
      });
      setLoading(false);
    };
    fetchOrders();
  }, []);

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      message.error("必须小于 3MB!");
    }
    //return isJpgOrPng && isLt2M;
    return false;
  };
  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      console.log(values);
      const res = await NoCodeOwnerService.add({
        ...values,
      });

      setLoading(false);
      if (!!res?.data?.message) {
        notification.success({
          message: res.data.message,
          description: "",
          placement: "topRight",
        });
      } else {
        openNotification("topRight");
        window.location.reload();
      }
    } catch (error) {
      setLoading(false);
    }

    // window.location.reload();
  };

  const onClose = () => {
    setOpenData(false);
  };

  const pageChangeHandle = async (page: number, pageSize: number) => {
    setSelectLoading(true);
    const res = await PhotoBarcodeService.fetchFilter(page);
    setPhoto({
      data: res.data.noOwnerBarCodePhotos,
      total: res.data.total,
    });
    setSelectLoading(false);
  };
  return (
    <Drawer
      title={`添加快拍`}
      placement="right"
      onClose={onClose}
      open={openData}
      width={"50%"}
    >
      <Spin spinning={loading} size="large">
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="条码" name="barCode" required>
            <Input />
          </Form.Item>
          <Form.Item label="图片" name="img">
            <Select
              style={{ width: "100%" }}
              showSearch
              placeholder="请选图片"
              optionFilterProp="children"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Pagination
                      defaultCurrent={1}
                      total={photo?.total}
                      pageSize={12}
                      onChange={pageChangeHandle}
                    />
                  </Space>
                </>
              )}
            >
              {photo?.data?.map((item: any) => (
                <Select.Option key={item._id} value={item._id}>
                  <Image width={55} src={API_IMG_NO_OWNER_URL + item.img} />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            wrapperCol={{ offset: 8, span: 16 }}

            // wrapperCol={{ offset: 8, span: 16 }}
          >
            <Button type="primary" htmlType="submit">
              添加
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default AddNoCodeOwner;
