import {
  Button,
  Card,
  ConfigProvider,
  Form,
  Select,
  Statistic,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { DatePicker, Space } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import FinanceService from "../../../../service/FinanceService";
import { getOrderStatusEnumText } from "../../../../utils/getEnumText";
import { formatDate } from "../../../../utils/notificationUtil";
// import locale from "antd/es/date-picker/locale/zh_CN";
const { RangePicker } = DatePicker;

const ChinaOrders = () => {
  // export enum ORDER_REPORT_STATUS {
  //   ARRIVED_AT_KAZAKHSTAN = "ARRIVED_AT_KAZAKHSTAN",
  //   SENT_FROM_KZ_INNER = "SENT_FROM_KZ_INNER",
  //   RECIEVED_FROM_KZ_INNER = "RECIEVED_FROM_KZ_INNER",
  //   IN_CHINA = "IN_CHINA",
  //   // WAITTING = "WAITTING",
  // }

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const [reports, setReports] = useState<any>([]);

  const columns = [
    // {
    //   title: "品名",
    //   dataIndex: "key",
    //   key: "key",
    // },
    {
      title: "追踪码",
      dataIndex: "barCode",
      key: "barCode",
    },

    {
      title: "号码组",
      key: "orderGroup",
      dataIndex: "orderGroup",
      render: (text: string, record: any) => {
        return <p>{record?.orderGroup?.title}</p>;
      },
    },

    {
      title: "状况",
      key: "status",
      dataIndex: "status",
      render: (text: string, record: any) => {
        const data = getOrderStatusEnumText(record.status);
        if (data) {
          return <Tag color={data.color}>{data.text}</Tag>;
        }
      },
    },

    {
      title: "日期/Дата",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (text: string, record: any) => {
        return <p>{formatDate(record.createdAt)}</p>;
      },
    },

    // {
    //   title: "更多",
    //   key: "action",
    //   render: (text: string, record: any) => (
    //     <>
    //       <Space size="middle">
    //         <Button
    //           danger
    //           type="primary"
    //           onClick={() => {
    //             deleteCityConfirm(record._id);
    //           }}
    //         >
    //           删除
    //         </Button>
    //       </Space>
    //       <Space size="middle">
    //         <Button
    //           type="primary"
    //           onClick={() => {
    //             showDrawerEdit(record);
    //           }}
    //         >
    //           修改
    //         </Button>
    //       </Space>
    //     </>
    //   ),
    // },
  ];

  const pageChangeHandle = async (page: number, pageSize: number) => {
    setLoading(true);
    const status = form.getFieldValue("status");
    const startDate = form.getFieldValue("startDate");
    const endDate = form.getFieldValue("endDate");
    const res = await FinanceService.reportChina(page, 12, {
      status,
      startDate,
      endDate,
    });
    setReports(res.data);
    setLoading(false);
  };

  const onFinish = async (value: any) => {
    // console.log(value);

    const response = await FinanceService.reportChina(1, 12, value);
    // console.log(response);
    setReports(response.data);
  };
  return (
    <>
      <Card>
        {" "}
        <Form
          form={form}
          layout="inline"
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            style={{ marginTop: 16 }}
            label="日期从/Дата от"
            name="startDate"
            required
          >
            <DatePicker locale={locale} />
            {/* </ConfigProvider> */}
          </Form.Item>

          <Form.Item
            style={{ marginTop: 16 }}
            label="日期至/Дата до"
            name="endDate"
            required
          >
            <DatePicker locale={locale} />
          </Form.Item>
          <Form.Item
            style={{ marginTop: 16 }}
            label="状态/Статус"
            name="status"
            required
          >
            <Select
              // defaultValue={type}
              style={{ width: 380 }}
              options={[
                {
                  value: "IN_CHINA",
                  label: "中国发货数量/Количество отгрузки в Китай",
                },
                {
                  value: "ARRIVED_AT_KAZAKHSTAN",
                  label: "哈国接货数量/Количество, полученных в Казахстане",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            style={{ marginTop: 16 }}
            // wrapperCol={{ offset: 8, span: 16 }}
          >
            <Button type="primary" htmlType="submit">
              OK
            </Button>
          </Form.Item>
        </Form>
      </Card>

      <Card>
        <Statistic title="订单数量/Количество заказов" value={reports?.total} />

        <Table
          loading={loading}
          rowKey={(record) => record._id}
          columns={columns}
          dataSource={reports?.result}
          scroll={{ y: 460 }}
          pagination={{
            pageSize: 12,
            total: reports?.total,
            current: reports?.currentPage,
            onChange: pageChangeHandle,
          }}
        />
      </Card>
    </>
  );
};

export default ChinaOrders;
