import { Card, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import CalculatorService from "../../../service/CalculatorService";
import { PLAN_TYPE } from "../../../service/OrderService";
import { CargoType } from "../../../service/PricelistService";
import {
  getCargoTypeEnumText,
  getPlanTypeEnumText,
} from "../../../utils/getEnumText";

interface CalculatorHistoryType {
  _id: string;
  weight: number;
  length: number;
  height: number;
  width: number;
  title: string;
  plan: PLAN_TYPE;
  cargo_type: CargoType;
  ip: string;
  result: string;

  createdAt: Date;

  updatedAt: Date;
}

function CalculatorHistory() {
  const [calculatorHistory, setCalculatorHistory] = useState<any>({
    data: [],
    total: 0,
  });
  const [loading, setLoading] = useState(true);
  const pageChangeHandle = async (page: number, pageSize: number) => {
    setLoading(true);
    const res = await CalculatorService.fetchFilter(page);
    setCalculatorHistory({
      data: res.data.calculators,
      total: res.data.total,
    });
    setLoading(false);
  };

  useEffect(() => {
    const fetchCalculators = async () => {
      const result = await CalculatorService.fetchFilter(1);
      setCalculatorHistory({
        data: result.data.calculators,
        total: result.data.total,
      });
      setLoading(false);
    };
    fetchCalculators();
  }, []);

  const columns = [
    {
      title: "品名",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "重量",
      dataIndex: "weight",
      key: "weight",
    },
    {
      title: "长度",
      dataIndex: "length",
      key: "length",
    },
    {
      title: "高度",
      dataIndex: "height",
      key: "height",
    },
    {
      title: "宽度",
      dataIndex: "width",
      key: "width",
    },
    {
      title: "密度",
      dataIndex: "density",
      key: "density",
    },
    {
      title: "运输类",
      key: "plan",
      dataIndex: "plan",
      render: (text: string, record: CalculatorHistoryType) => {
        const data = getPlanTypeEnumText(record.plan);
        if (data) {
          return <Tag color={data.color}>{data.text}</Tag>;
        }
      },
    },
    {
      title: "货物类别",
      key: "cargo_type",
      dataIndex: "cargo_type",
      render: (text: string, record: CalculatorHistoryType) => {
        const data = getCargoTypeEnumText(record.cargo_type);
        if (data) {
          return <Tag color={data.color}>{data.text}</Tag>;
        }
      },
    },
    {
      title: "用户IP",
      dataIndex: "ip",
      key: "ip",
    },
    {
      title: "结果",
      dataIndex: "result",
      key: "result",
      render: (text: string, record: CalculatorHistoryType) => (
        <Tag color={Number(record.result) ? "#2db7f5" : "#f50"}>
          {record.result}
        </Tag>
      ),
    },
  ];
  return (
    <Card>
      <Table
        loading={loading}
        dataSource={calculatorHistory.data}
        columns={columns}
        rowKey="_id"
        pagination={{
          pageSize: 12,
          total: calculatorHistory.total,
          onChange: pageChangeHandle,
        }}
      />
    </Card>
  );
}

export default CalculatorHistory;
