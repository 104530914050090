import {
  Button,
  Checkbox,
  Drawer,
  Form,
  Input,
  notification,
  Select,
  Spin,
} from "antd";
import { NotificationPlacement } from "antd/lib/notification";
import React, { useState } from "react";
import FaqService, { CargoType } from "../../../../service/FaqService";
import TextArea from "antd/lib/input/TextArea";

function AddFaq({ openData, setOpenData }: any) {
  const [loading, setLoading] = useState(false);
  const openNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: `Success`,
      description: "添加成功",
      placement,
    });
  };
  const onFinish = async (values: any) => {
    setLoading(true);
    await FaqService.add({
      ...values,
      isMain: values.isMain === true ? true : false,
    });
    openNotification("topRight");
    setLoading(false);
  };
  const keys1 = Object.keys(CargoType);
  const onClose = () => {
    setOpenData(false);
  };
  return (
    <Drawer
      title="添加分部"
      placement="right"
      onClose={onClose}
      open={openData}
      width={"50%"}
    >
      <Spin spinning={loading} size="large">
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="问题kz" name="questionKZ" required>
            <TextArea />
          </Form.Item>

          <Form.Item label="问题RU" name="questionRU" required>
            <TextArea />
          </Form.Item>

          <Form.Item label="问题CN" name="questionCN" required>
            <TextArea />
          </Form.Item>

          <Form.Item label="答案RU" name="answerRU" required>
            <TextArea />
          </Form.Item>

          <Form.Item label="答案KZ" name="answerKZ" required>
            <TextArea />
          </Form.Item>

          <Form.Item label="答案CN" name="answerCN" required>
            <TextArea />
          </Form.Item>

          <Form.Item
            wrapperCol={{ offset: 8, span: 16 }}

            // wrapperCol={{ offset: 8, span: 16 }}
          >
            <Button type="primary" htmlType="submit">
              添加
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default AddFaq;
