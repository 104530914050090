import { Button, Card, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import SettingService from "../../../../service/SettingService";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import confirm from "antd/lib/modal/confirm";
import AddSetting from "./AddSetting";
import EditSetting from "./EditSetting";

const Setting = () => {
  const [openAdd, setOpenAdd] = useState(false);

  const [loading, setLoading] = useState(false);

  const [openEdit, setOpenEdit] = useState({
    open: false,
    settingId: "",
  });
  const [Setting, setSetting] = useState<any>([]);

  const showDrawerAdd = () => {
    setOpenAdd(true);
  };

  const showDrawerEdit = (record: any) => {
    setOpenEdit({ open: true, settingId: record._id });
  };

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      const result = await SettingService.fetchFilter(1);

      setSetting({
        data: result.data.settings,
        total: result.data.total,
      });
      setLoading(false);
    };
    fetchOrders();
  }, []);

  const columns = [
    // {
    //   title: "品名",
    //   dataIndex: "key",
    //   key: "key",
    // },
    {
      title: "汇率/курс обмена",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "一公斤价格（阿拉木图）/ Цена за килограмм (Алматы)",
      dataIndex: "price",
      key: "price",
    },

    {
      title: "更多",
      key: "action",

      render: (text: string, record: any) => (
        <>
          <Space size="middle">
            <Button
              danger
              type="primary"
              onClick={() => {
                deleteSettingConfirm(record._id);
              }}
            >
              删除/удалить
            </Button>
          </Space>
          <Space size="middle">
            <Button
              type="primary"
              onClick={() => {
                showDrawerEdit(record);
              }}
            >
              修改/Редактировать
            </Button>
          </Space>
        </>
      ),
    },
  ];

  const pageChangeHandle = async (page: number, pageSize: number) => {
    setLoading(true);
    const res = await SettingService.fetchFilter(page);
    setSetting({
      data: res.data.settings,
      total: res.data.total,
    });
    setLoading(false);
  };

  const deleteSetting = async (id: string) => {
    await SettingService.delete(id);
    window.location.reload();
  };

  const deleteSettingConfirm = (id: string) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "确认删除？",
      okText: "确认",
      cancelText: "取消",
      onOk() {
        deleteSetting(id);
      },
    });
  };
  return (
    <Card>
      {" "}
      <Button
        onClick={showDrawerAdd}
        style={{
          marginTop: 20,
          marginBottom: 20,
          marginLeft: 20,
          backgroundColor: "green",
        }}
        type="primary"
      >
        添加
      </Button>
      <Table
        loading={loading}
        dataSource={Setting.data}
        columns={columns}
        rowKey="_id"
        pagination={{
          pageSize: 12,
          total: Setting.total,
          onChange: pageChangeHandle,
        }}
      />
      {openAdd && <AddSetting openData={openAdd} setOpenData={setOpenAdd} />}
      {openEdit.open && (
        <EditSetting openData={openEdit} setOpenData={setOpenEdit} />
      )}
    </Card>
  );
};

export default Setting;
