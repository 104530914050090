import userSaga from "./root/userRoot";

import { all } from "redux-saga/effects";
import customerSaga from "./root/customerRoot";
export default function* rootSaga() {
  yield all(
    [userSaga(), customerSaga()]
    // other sagas
  );
}
