import {
  Button,
  Checkbox,
  Drawer,
  Form,
  Input,
  notification,
  Select,
  Spin,
} from "antd";
import { NotificationPlacement } from "antd/lib/notification";
import React, { useEffect, useRef, useState } from "react";
import FaqService, { CargoType } from "../../../../service/FaqService";
import TextArea from "antd/lib/input/TextArea";

function EditFaq({ openData, setOpenData }: any) {
  const formRef = useRef<any>();
  const [loading, setLoading] = useState(true);
  const keys1 = Object.keys(CargoType);

  useEffect(() => {
    const fetchFaq = async () => {
      const response = await FaqService.getOne(openData.FaqId);

      formRef.current.setFieldsValue({
        _id: response.data._id,
        questionKZ: response.data.questionKZ,
        questionRU: response.data.questionRU,
        questionCN: response.data.questionCN,
        answerRU: response.data.answerRU,
        answerKZ: response.data.answerKZ,
        answerCN: response.data.answerCN,
      });
      setLoading(false);
    };
    fetchFaq();
  }, []);

  const openNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: `Success`,
      description: "更改成功",
      placement,
    });
  };
  const onClose = () => {
    setOpenData({ open: false, FaqId: "" });
  };
  const onFinish = async (values: any) => {
    setLoading(true);

    // _id: response.data._id,
    //     titleRU: response.data.titleRU,
    //     titleKZ: response.data.titleKZ,
    //     isMain: response.data.isMain,

    await FaqService.update(values, openData.FaqId);
    openNotification("topRight");
    setLoading(false);
  };

  return (
    <Drawer
      title="修改FAQ"
      placement="right"
      onClose={onClose}
      open={openData.open}
      width={"50%"}
    >
      <Spin size="large" spinning={loading}>
        <Form
          ref={formRef}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="问题kz" name="questionKZ" required>
            <TextArea />
          </Form.Item>

          <Form.Item label="问题RU" name="questionRU" required>
            <TextArea />
          </Form.Item>

          <Form.Item label="问题CN" name="questionCN" required>
            <TextArea />
          </Form.Item>

          <Form.Item label="答案RU" name="answerRU" required>
            <TextArea />
          </Form.Item>

          <Form.Item label="答案KZ" name="answerKZ" required>
            <TextArea />
          </Form.Item>

          <Form.Item label="答案CN" name="answerCN" required>
            <TextArea />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              确认
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default EditFaq;
