import { Button, Card, Input, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { getCabinetOrderStatusEnumTextRus } from "../../../../utils/getEnumText";
import { CABINET_ORDER_STATAUS } from "../ICabinet";
import { RightCircleOutlined, SearchOutlined } from "@ant-design/icons";
import CustomerService from "../../../../service/CustomerService";
import CabinetService from "../../../../service/CabinetService";
import { OrderStatus } from "../../home/statusOrder";
import debounce from "lodash.debounce";
import { useSelector } from "react-redux";
import { selectId } from "../../../../redux/slices/customerSlice";
import { Link, useParams } from "react-router-dom";
import { IBarCodesList } from "./CabinetDataTable";
import AddNewBarCode from "./add-new-barcode";
import { count } from "console";

const CabinetStatus = () => {
  const [cabinetStatusInfo, setCabinetStatusInfo] = useState([
    { _id: [], count: 0 },
  ]);

  // const customerId = useSelector(selectId);
  let params = useParams();

  useEffect(() => {
    let type = params.barCodeType || "";
    async function fetchOrderStatus(type: string) {
      const res = await CabinetService.getStatusCount(type);

      setCabinetStatusInfo(res.data);
    }
    fetchOrderStatus(type);
  }, [params.barCodeType]);

  const getStatusCount = (status: OrderStatus | string | null) => {
    const res = cabinetStatusInfo.find((el) => el._id[0] === status);
    if (!res) {
      return { _id: [], count: 0 };
    }
    return res;
  };

  const corresponStatus = (status: CABINET_ORDER_STATAUS | string | null) => {
    if (CABINET_ORDER_STATAUS.IN_KAZAKHSTAN === status) {
      return getStatusCount(OrderStatus.ARRIVED_AT_KAZAKHSTAN)?.count;
    } else if (CABINET_ORDER_STATAUS.DELIVERED === status) {
      return getStatusCount(OrderStatus.DELIVERED)?.count;
    } else if (CABINET_ORDER_STATAUS.IN_CHINA === status) {
      return (
        getStatusCount(OrderStatus.SENT_FROM_CHINA)?.count +
        getStatusCount(OrderStatus.BORDER)?.count
      );
    } else if (CABINET_ORDER_STATAUS.WAITTING === status) {
      return (
        getStatusCount(OrderStatus.PENDING)?.count + getStatusEmpty()?.count
      );
    } else {
      return 0;
    }
  };

  const getStatusEmpty = () => {
    const res = cabinetStatusInfo.find((el) => {
      if (el._id.length === 0) {
        return el;
      }
    });

    if (!res) {
      return { _id: [], count: 0 };
    }

    return res;
  };

  // useEffect(() => {
  //   sendQuery("");
  //   // setSearchQuery("");
  // }, [params.barCodeType, customerId]);

  return (
    <div className="cabinet_status">
      {Object.keys(CABINET_ORDER_STATAUS).map((item) => (
        <Link
          key={item + "key"}
          to={"/cabinet/" + params.barCodeType + "/" + item}
        >
          <Card hoverable={false}>
            <Row align="middle" justify="space-between">
              <h1>{getCabinetOrderStatusEnumTextRus(item)?.text}</h1>
              <RightCircleOutlined style={{ color: "grey", fontSize: 16 }} />
            </Row>
            <span style={{ color: "grey", fontSize: "10" }}>
              {corresponStatus(item) || 0} заказов
            </span>
          </Card>
        </Link>
      ))}
    </div>
  );
};

export default CabinetStatus;
