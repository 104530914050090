import fetch from "../auth/FetchInterceptor";
import fetchCustomerApi from "../auth/PhoneInterceptor";

export interface ICustomerRegister {
  telephone: string;
  password: string;
  name: string;
}

export interface ICustomerVerify {
  telephone: string;
  user_input: string;
}

export interface ICustomerLogin {
  telephone: string;
  password: string;
}

const CustomerService = {
  register: function (data: ICustomerRegister) {
    return fetchCustomerApi({
      url: `/register`,
      method: "post",
      data: data,
      headers: {
        "public-request": "true",
      },
    });
  },
  verify: function (data: ICustomerVerify) {
    return fetchCustomerApi({
      url: `/verify-customer`,
      method: "post",
      data: data,
      headers: {
        "public-request": "true",
      },
    });
  },

  login: function (data: ICustomerLogin) {
    return fetchCustomerApi({
      url: `/customer/login`,
      method: "post",
      data: data,
      headers: {
        "public-request": "true",
      },
    });
  },

  // check: function (telephone: string) {
  //   return fetchCustomerApi({
  //     url: `/fetch-customer/${telephone}`,
  //     method: "get",
  //     //   data: data,
  //     headers: {
  //       "public-request": "true",
  //     },
  //   });
  // },

  sendCode: function (telephone: string) {
    return fetchCustomerApi({
      url: `/sms-customer`,
      method: "post",
      data: { telephone },
      headers: {
        "public-request": "true",
      },
    });
  },
  // send-code-forget-password
  forgetPass: function (telephone: string, user_input: string) {
    return fetchCustomerApi({
      url: `/forget-password`,
      method: "post",
      data: { telephone, user_input },
      headers: {
        "public-request": "true",
      },
    });
  },

  sendCodeforgetPass: function (telephone: string) {
    return fetchCustomerApi({
      url: `/send-code-forget-password`,
      method: "post",
      data: { telephone },
      headers: {
        "public-request": "true",
      },
    });
  },

  changePassword: function (data: {
    telephone: string;
    user_input: string;
    password: string;
    passwordConfirm: string;
  }) {
    return fetchCustomerApi({
      url: `/change-password`,
      method: "post",
      data,
      headers: {
        "public-request": "true",
      },
    });
  },
};

export default CustomerService;
