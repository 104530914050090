import { Button, Drawer, Form, Input, notification, Select, Spin } from "antd";
import { NotificationPlacement } from "antd/lib/notification";
import React, { useState } from "react";
import PricelistService, { CargoType } from "../../../service/PricelistService";

function AddPriceList({ openData, setOpenData }: any) {
  const [loading, setLoading] = useState(false);
  const openNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: `Success`,
      description: "添加成功",
      placement,
    });
  };
  const onFinish = async (values: any) => {
    setLoading(true);
    await PricelistService.add({
      density_from: parseInt(values.density_from),
      density_to: parseInt(values.density_to),
      express_plan: parseFloat(values.express_plan),
      plane_plan: parseFloat(values.plane_plan),
      standard_plan: parseFloat(values.standard_plan),
      train_plan: parseFloat(values.train_plan),
      cargo_type: values.cargo_type,
    });
    openNotification("topRight");
    setLoading(false);
  };
  const keys1 = Object.keys(CargoType);
  const onClose = () => {
    setOpenData(false);
  };
  return (
    <Drawer
      title="添加价格表"
      placement="right"
      onClose={onClose}
      open={openData}
    >
      <Spin spinning={loading} size="large">
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="密度1" name="density_from">
            <Input type="number" />
          </Form.Item>

          <Form.Item label="密度2" name="density_to">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="飞机运$" name="plane_plan">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="火车运$" name="train_plan">
            <Input />
          </Form.Item>
          <Form.Item label="标准运$" name="standard_plan">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="快运$" name="express_plan">
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="货类"
            name="cargo_type"
            rules={[{ required: true, message: "请选择货类" }]}
          >
            <Select style={{ width: 120 }}>
              {keys1.map((key) => (
                <Select.Option key={key} value={key}>
                  {key}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default AddPriceList;
