import { Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { NavLink, Route, Routes, useLocation } from "react-router-dom";
import Setting from "./settings/Setting";
import KazakhstanOrdes from "./kazakhstan/KazakhstanOrders";
import ChinaOrders from "./china/ChinaOrders";
import Finance from "./finance/Finance";
const { Header, Content, Sider } = Layout;
const Report = () => {
  const location = useLocation();
  const lastPath = location.pathname;
  const [selected, setSelected] = useState("");

  useEffect(() => {
    if (lastPath.includes("settings")) {
      setSelected("settings");
    }
    if (lastPath.includes("china")) {
      setSelected("china");
    }
    if (lastPath.includes("kazakhstan")) {
      setSelected("kazakhstan");
    }
    if (lastPath.includes("finance")) {
      setSelected("finance");
    }
  }, [lastPath]);

  return (
    <Layout>
      <Layout>
        <Sider width={200}>
          <Menu
            style={{ height: "100%", borderRight: 0 }}
            selectedKeys={[selected]}

            // selectedKeys={}
          >
            <Menu.Item key="settings">
              {" "}
              <NavLink
                to="/admin/report/settings"
                // className={({ isActive }) =>
                //   isActive ? activeClassName : undefined
                // }
              >
                设置/Настройки
              </NavLink>
            </Menu.Item>
            <Menu.Item key="china">
              {" "}
              <NavLink
                to="/admin/report/china"
                // className={({ isActive }) =>
                //   isActive ? activeClassName : undefined
                // }
              >
                中国出货量/Отгрузки из Китая
              </NavLink>
            </Menu.Item>
            <Menu.Item key="kazakhstan">
              {" "}
              <NavLink
                to="/admin/report/kazakhstan"
                // className={({ isActive }) =>
                //   isActive ? activeClassName : undefined
                // }
              >
                哈国出货量/Отгрузки из Казахстана
              </NavLink>
            </Menu.Item>
            <Menu.Item key="finance">
              {" "}
              <NavLink
                to="/admin/report/finance"
                // className={({ isActive }) =>
                //   isActive ? activeClassName : undefined
                // }
              >
                金融/Финансы
              </NavLink>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout style={{ padding: "0 24px 24px", height: "100%" }}>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            <Routes>
              <Route path="settings" element={<Setting />} />
              <Route path="kazakhstan" element={<KazakhstanOrdes />} />
              <Route path="finance" element={<Finance />} />
              <Route path="china" element={<ChinaOrders />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Report;
