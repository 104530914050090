import { Button, Card, Modal, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CityService from "../../../service/CityService";
import AddCity from "./AddCity";
import EditCity from "./EditCity";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

const City = () => {
  const [openAdd, setOpenAdd] = useState(false);

  const [loading, setLoading] = useState(false);

  const [openEdit, setOpenEdit] = useState({
    open: false,
    CityId: "",
  });
  const [City, setCity] = useState<any>([]);

  const showDrawerAdd = () => {
    setOpenAdd(true);
  };

  const showDrawerEdit = (record: any) => {
    setOpenEdit({ open: true, CityId: record._id });
  };

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      const result = await CityService.fetchFilter(1);

      setCity({
        data: result.data.cities,
        total: result.data.total,
      });
      setLoading(false);
    };
    fetchOrders();
  }, []);

  const columns = [
    // {
    //   title: "品名",
    //   dataIndex: "key",
    //   key: "key",
    // },
    {
      title: "名称kz",
      dataIndex: "titleKZ",
      key: "titleKZ",
    },
    {
      title: "名称RU",
      dataIndex: "titleRU",
      key: "titleRU",
    },
    {
      title: "名称CN",
      dataIndex: "titleCN",
      key: "titleCN",
    },

    {
      title: "备注",
      dataIndex: "shortTitle",
      key: "shortTitle",
    },

    {
      title: "分部",
      dataIndex: "isMain",
      key: "isMain",
      render: (text: string, record: any) => (
        <span>
          {record.isMain ? (
            <Tag color="blue"> 主分部 </Tag>
          ) : (
            <Tag color="grey"> 次分部 </Tag>
          )}
        </span>
      ),
    },

    {
      title: "更多",
      key: "action",
      render: (text: string, record: any) => (
        <>
          <Space size="middle">
            <Button
              danger
              type="primary"
              onClick={() => {
                deleteCityConfirm(record._id);
              }}
            >
              删除
            </Button>
          </Space>
          <Space size="middle">
            <Button
              type="primary"
              onClick={() => {
                showDrawerEdit(record);
              }}
            >
              修改
            </Button>
          </Space>
        </>
      ),
    },
  ];

  const pageChangeHandle = async (page: number, pageSize: number) => {
    setLoading(true);
    const res = await CityService.fetchFilter(page);
    setCity({
      data: res.data.cities,
      total: res.data.total,
    });
    setLoading(false);
  };

  const deleteCity = async (id: string) => {
    await CityService.delete(id);
    window.location.reload();
  };

  const deleteCityConfirm = (id: string) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "确认删除？",
      okText: "确认",
      cancelText: "取消",
      onOk() {
        deleteCity(id);
      },
    });
  };

  return (
    <Card>
      <Button
        onClick={showDrawerAdd}
        style={{
          marginTop: 20,
          marginBottom: 20,
          marginLeft: 20,
          backgroundColor: "green",
        }}
        type="primary"
      >
        添加
      </Button>
      <Table
        loading={loading}
        dataSource={City.data}
        columns={columns}
        rowKey="_id"
        pagination={{
          pageSize: 12,
          total: City.total,
          onChange: pageChangeHandle,
        }}
      />
      {openAdd && <AddCity openData={openAdd} setOpenData={setOpenAdd} />}
      {openEdit.open && (
        <EditCity openData={openEdit} setOpenData={setOpenEdit} />
      )}
    </Card>
  );
};

export default City;
